import { Event } from "../Event";

export class ChecklistUserEvents extends Event {
    public onLoad = () => {
        const { role } = this.userContextProps
        const { url } = this.globalProps.match;
        
        if (this.globalProps.values.user_checklist){
            this.setField("user_checklist_tables", {required: true, hidden: false})

        } else {
            this.setField("user_checklist_tables", {hidden: true})
        }


        if (this.globalProps.isEdit && role.role_is_system_manager) {
            this.setField("user_reset_password", {hidden: false})
        } else {
            this.setField("user_name", {width: 6})
            this.setField("user_cpf_cnpj", {width: 3})
        }
    }
}