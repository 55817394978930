export class ParametersGlobals {

    // Parâmetros padrão para Horário de Funcionários com base alterada
    static defaultHourStart: string = "0800";
    static defaultHourEnd: string = "1620";
    static defaultHourInterval: string = "0100";
    static defaultHourAmountJourney: string = "0720";

    // Parâmetros Genéricos
    static useMapGoogleView: string = 'http://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
    static perPage: number = 50;
}