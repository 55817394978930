import classNames from "classnames";
import React from "react";
import { Panel, Nav, Navbar, Button, ControlLabel, Divider, ErrorMessage, FormGroup, HelpBlock, Message, Placeholder, Toggle, Modal, Notification, Whisper, Tooltip, Popover } from "rsuite";
import qs from "query-string";
import { Conditionizer } from "./Conditionizer";
import { AxiosSelectPicker } from "./formfields/AxiosSelectPicker";
import { MaskInput } from "./formfields/MaskInput";
import * as Events from "../events/EventHolder";
import { ErrorPopover } from "./errorPopover/main";
import { Grid } from "./formfields/Grid";
import { ColorPicker } from "./formfields/ColorPicker";
import { WYSIWYG } from "./formfields/WYSIWYG";
import { NumberInput } from "./formfields/NumberInput";
import { FileInput } from "./formfields/FileInput";
import { ListInput } from "./formfields/ListInput";
import _ from "lodash";
import pluralize from "pluralize";
import { DynamicTree } from "./formfields/DynamicTree";
import { CheckTreePicker } from "./formfields/CheckTreePicker";
import { Permission } from "./Permission";
import { DayPicker } from "./formfields/DayPicker";
import axios from "./../utilities/axios";
import { AxiosCascadePicker } from "./formfields/AxiosCascadePicker";
import { Event } from "../events/Event";
import { RouteComponentProps, withRouter } from "react-router";
import CustomComponents from "./formfields/custom/CustomList";
import { UserContext } from "../components/authProvider/main";
import { DatePickerComponent } from "./formfields/DatePicker";
import { RolePermissions, UserPermission } from "../core/enums/RolePermissions";

interface IField {
    width?: number;
    type?: string;
    order?: number;
    name: string;
    label?: string;
    minlength?: number;
    regex?: string;
    maxlength?: number;
    required?: boolean;
    mask?: string[];
}

interface IFormalize {
    form: any;
    values?: any;
    history?: any;
    name: string;
    onDirty?: Function;
    errors?: any;
    isEdit?: boolean;
    submitButton?: any;
    cache?: any;
    match?: any;
    readOnly?: boolean;
    parentValues?: any;
    isModal?: boolean;
    onSubmit?: Function;
    [key: string]: any;
}

class Formalize extends React.Component<RouteComponentProps<any> & IFormalize, {}> {
    static contextType?: React.Context<any> | undefined = UserContext;
    public state: any = {
        oldValues: this.props.values ? this.props.values : {},
        formValues: this.props.values ?? {},
        resultValues: {},
        fieldsToUpdate: {},
        errors: this.props.errors ? this.props.errors : {},
        formData: {},
        originalForm: this.props.form,
        crud: this.props.form?.$GLOBALS?.table,
        loadings: [],
        guid: null,
        cachedData: this.props.cache ?? {},
        fieldStates: {},
        priorityFormData: {},
        totalFieldsPerTab: {},
        customTabs: [
            // { key: "tab-key", label: "Tab Label", jsxComponent: <div>Olá!</div> }
        ],
        hasBooted: false,
        isFormDirty: false,
        debugMode: false,
        activeTab: null,
        isLoading: true,
        tabsLoaded: ['Main']
    };

    public uses: any = {};

    public genericEventHandler: any | Event = null;

    public instanced = false;
    public totalFieldsPerTab: any = {};
    public latestFields: any = {};

    addTab = (tabKey: string, tabLabel: string, jsxComponent: any) => {
        this.setState({
            customTabs: [
                ...this.state.customTabs,
                {
                    key: tabKey,
                    label: tabLabel,
                    jsxComponent: jsxComponent,
                },
            ],
        });
    };

    buildFields = (rebuildFields: any = [], newValues: any = {}, priorityForm: any = {}) => {
        // alert("FIELDS REBUILT");
        var newForm: any = {};
        var totalFieldsPerTab: any = {};
        var $GLOBALS = this.props.form.$GLOBALS;
        // const availableEvents : any = Events;
        // var customSettings = null;
        // if($GLOBALS?.prefix && $GLOBALS?.prefix.length > 0) {
        //     customSettings = availableEvents[_.upperFirst($GLOBALS?.prefix) + "Settings"];
        // }
        // console.log($GLOBALS.table + "Settings",);
        this.setState({ isLoading: true });
        if (rebuildFields.length) {
            for (var indRebuild in rebuildFields) {
                var entranceField = this.props.form[rebuildFields[indRebuild]];
                if (entranceField && entranceField.name) {
                    // alert(entranceField.name);
                    let field = Conditionizer.set(this.props.form).getProps(entranceField.name, { ...this.state.oldValues, ...this.state.formValues, ...newValues });
                    if (field.tabs !== null && field.tabs !== undefined && !field.tabs.includes(this.state.activeTab)) {
                    }

                    newForm[field.name] = field;
                }
            }
            var mergedForm = { ...this.props.form, ...newForm };
            for (var indMergForm in mergedForm) {
                if (indMergForm !== "$GLOBALS") {
                    let field = mergedForm[indMergForm];
                    if (field.canAdd === false && this.props.isEdit === false) {
                        continue;
                    }
                    if (field.hidden !== true) {
                        for (var tabIndex in field.tabs) {
                            if (!totalFieldsPerTab[field.tabs[tabIndex]]) {
                                totalFieldsPerTab[field.tabs[tabIndex]] = [];
                            }
                            totalFieldsPerTab[field.tabs[tabIndex]].push(field.name);
                            break;
                        }
                    }
                }
            }
            var formData = { ...mergedForm, ...newForm };
            /*if(customSettings && customSettings.rules && customSettings.rules.fields) {
                for(var ruleFieldName in customSettings.rules.fields) {
                    if(typeof customSettings.rules.fields[ruleFieldName] == 'function') {
                        formData[ruleFieldName] = customSettings.rules.fields[ruleFieldName](formData[ruleFieldName],formValues[ruleFieldName],{ ...this.state.formValues });
                    }
                }
            }*/
            var formValues = { ...this.state.formValues };
            for (var i in newValues) {
                formValues[i] = newValues[i];
            }
            this.setState({ formValues, formData, totalFieldsPerTab: totalFieldsPerTab });
        } else {
            // alert("FIELDS FULLY REBUILT");
            var { tab } = qs.parse(this.props.history.location.search);
            for (let i in this.props.form) {
                if (i !== "$GLOBALS") {
                    let entranceField = this.props.form[i];
                    if (!entranceField) continue;
                    if (!entranceField.name) {
                        continue;
                    }

                    let field = Conditionizer.set(this.props.form).getProps(entranceField.name, { ...this.state.oldValues, ...this.state.formValues });
                    if (field.canAdd === false && this.props.isEdit === false && this.props.readOnly === false) {
                        continue;
                    }
                    newForm[field.name] = field;
                    if (!this.uses[field.name]) {
                        this.uses[field.name] = [];
                    }
                    for (var cond in field.conditions) {
                        var condition = field.conditions[cond];
                        if (condition.query) {
                            let uses: any = _.uniq(
                                _.flatten(
                                    Object.values(condition.query).map((item: any) => {
                                        return item
                                            ? Object.values(item).map((subitem: any) => {
                                                return Object.keys(subitem)[0];
                                            })
                                            : undefined;
                                    }),
                                ),
                            );
                            this.uses[field.name] = [...this.uses[field.name], ...uses];
                        }
                        if (condition.cases) {
                            let uses: any = [];
                            for (var cases of condition.cases) {
                                if (!uses.includes(cases.field)) {
                                    uses.push(cases.field);
                                }
                            }
                            this.uses[field.name] = [...this.uses[field.name], ...uses];
                        }

                        this.uses[field.name] = _.uniq(this.uses[field.name]);
                    }
                    if (field.hidden !== true) {
                        for (let tabIndex in field.tabs) {
                            if (!totalFieldsPerTab[field.tabs[tabIndex]]) {
                                totalFieldsPerTab[field.tabs[tabIndex]] = [];
                            }
                            totalFieldsPerTab[field.tabs[tabIndex]].push(field.name);
                            break;
                        }
                    }
                }
            }
            var activeTab: string = tab ? tab : this.state.activeTab;
            if ($GLOBALS && $GLOBALS.tabs && $GLOBALS.tabs.length && (activeTab === null || !Object.keys(totalFieldsPerTab).includes(activeTab)) && $GLOBALS.tabs[0]) {
                activeTab = $GLOBALS.tabs[0].key;
            }
            // alert("FORM BUILT");
            // ({ values: { ...this.state.oldValues, ...this.state.formValues } });
            const conjoinedNewForm = newForm;
            // alert(JSON.stringify(this.state.priorityFormData));
            for (var fieldName in priorityForm) {
                var field = priorityForm[fieldName];
                for (var prop in field) {
                    if (!conjoinedNewForm[fieldName]) {
                        conjoinedNewForm[fieldName] = {};
                    }
                    conjoinedNewForm[fieldName][prop] = field[prop];
                }
            }
            // var loadings = [];
            /*if(customSettings && customSettings.rules?.fields) {
                for(var ruleFieldName in customSettings.rules.fields) {
                    if(typeof customSettings.rules.fields[ruleFieldName] == 'function') {
                        var resultField = customSettings.rules.fields[ruleFieldName](conjoinedNewForm[ruleFieldName],newValues[ruleFieldName] ?? undefined,{ ...newValues,...this.props.values });
                        if(!_.isEqual(resultField,conjoinedNewForm[ruleFieldName])) {
                            // this._forceUpdate = true;
                            conjoinedNewForm[ruleFieldName] = resultField;
                            console.log({resultField});
                            if((resultField.hidden === undefined || resultField.hidden === false || (resultField.hidden && resultField.hidden !== true)) && resultField.tabs) {
                                for(var customFieldTabIndex in resultField.tabs) {
                                    
                                    if(!totalFieldsPerTab[resultField.tabs[customFieldTabIndex]]) {
                                        totalFieldsPerTab[resultField.tabs[customFieldTabIndex]] = []
                                    }
                                    totalFieldsPerTab[resultField.tabs[customFieldTabIndex]].push(ruleFieldName);
                                }
                                
                            }
                            // loadings.push(ruleFieldName);
                        }
                    
                        
                        
                        // console.log(ruleFieldName,"SET TO",resultField)
                    }
                }
            }*/
            // console.log({activeTab});
            var updatedState: any = { formData: conjoinedNewForm, isLoading: false, totalFieldsPerTab, activeTab };
            /*if(this.state.activeTab) {
                delete updatedState.activeTab;
            }*/
            this.setState(updatedState);
        }
        const eventName = _.upperFirst(pluralize.singular(_.camelCase(this.state.crud))) + "Events";
        const eventHolder: any = Events;
        // alert(eventName);
        if (eventHolder[eventName]) {
            const event = eventHolder[eventName];
            const instancedEvent = new event(
                null,
                this.state,
                this.setStateProperty,
                this.setFieldState,
                this.setExternalFieldValue,
                this.setFieldProperty,
                this.props.history,
                this.props.match,
                this.props,
                this.addTab,
                this.props.userContext?.data ? this.props.userContext.data : {}
            );
            if (this.state.hasBooted === false) {
                if (!this.props.isEdit) {
                    if (instancedEvent["onInit"]) {
                        // console.log("Has Intanced :::",instancedEvent["onInit"])
                        //  console.log("Has parent Values :::", this.props.parentValues)
                        instancedEvent["onInit"](this.props.parentValues);
                    }
                }
                if (instancedEvent["onLoad"]) {
                    instancedEvent["onLoad"]();
                }
            }

            this.genericEventHandler = instancedEvent;
        }
        if (this.state.hasBooted === false) {
            this.setState({ hasBooted: true });
        }

        // alert(eventName);
    };

    componentDidMount() {
        // console.log("Meu started:::: ", this.starterValues);
        // console.log("Meu FormValues :::: ", this.state.formValues);
        this.buildFields();
        this.setState({ guid: this.guid });
        if (this.props.isEdit === false) {
            const settingsName = _.upperFirst(pluralize.singular(this.state.crud)) + "Settings";
            const events: any = Events;
            const eventClass = events[settingsName];
            if (eventClass) {
                const { defaults } = eventClass;
                for (const defaultFieldName in defaults) {
                    const defaultValue = defaults[defaultFieldName];
                    const field = this.props.form[defaultFieldName];
                    if (field) {
                        switch (field.type) {
                            case "select":
                                this.setStateFieldValue(defaultFieldName, defaultValue);
                                break;
                        }
                    }
                }
                // //console.log({defaults,eventClass});
            }

            // alert("LOADING DEFAULTS FOR "+settingsName);
        }
    }

    renderFields = () => {
        const formLayout: any = this.state.formData;
        var output = [];
        if (formLayout) {
            var orderedFormLayout: any = Object.values(formLayout).sort((a: any, b: any) => {
                return a.order < b.order ? -1 : 1;
            });
            // var count = 0;
            for (var i in orderedFormLayout) {
                // count++;
                if (orderedFormLayout[i] !== undefined && orderedFormLayout[i] !== null) {
                    var field: IField = orderedFormLayout[i];
                    if (field.name === "$GLOBALS") continue;
                    var resultField = this.renderEachFieldType(field, i);
                    output.push(resultField);
                }
            }
        }
        return output;
    };

    renderField = (field: IField, index: string, isLastField: boolean = false) => {
        return this.renderEachFieldType(field, index);
    };

    public _loadedOldValues = false;
    public _forceUpdate = false;
    public hasChanged: string[] = [];

    checkGenericEvent = (eventName: string, fieldName: string) => {
        if (this.genericEventHandler?.onEvents) {
            if (this.genericEventHandler?.onEvents[eventName]) {
                if (this.genericEventHandler?.onEvents[eventName].listeners?.includes(fieldName)) {
                    const action = this.genericEventHandler?.onEvents[eventName].action;
                    return this.genericEventHandler[action];
                }
            }
        }
        return null;
    };

    shouldComponentUpdate = (props: any, state: any) => {
        var shouldUpdate = false;

        // return true;
        // return false;

        //({ stateFieldStates: state.fieldStates, oldStateFields: this.state.fieldStates });
        if (this.state.fieldStates !== state.fieldStates) {
            shouldUpdate = true;
        }
        if (this.state.customTabs !== state.customTabs) {
            shouldUpdate = true;
        }
        if (this.state.debugMode !== state.debugMode) {
            shouldUpdate = true;
        }

        if (this.state.isFormDirty !== state.isFormDirty) {
            shouldUpdate = true;
        }
        // if(this.props !== props) {
        //     shouldUpdate = true;
        // }
        if (!_.isEqual(this.props.errors, props.errors)) {
            this.setState({ errors: props.errors });
            //
            shouldUpdate = true;
        }
        var shouldUpdateFields = false;
        if (this.state.priorityFormData !== state.priorityFormData) {
            shouldUpdate = true;
            shouldUpdateFields = true;
            // this.buildFields([], state.formValues);
        }
        //
        if (!_.isEqual(this.state.cachedData, state.cachedData)) {
            shouldUpdate = true;
        }
        if (!_.isEqual(this.state.formData, state.formData)) {
            shouldUpdate = true;
        }
        if (!_.isEqual(this.state.errors, state.errors)) {
            shouldUpdateFields = true;
            shouldUpdate = true;
        }
        if (Object.keys(this.state.totalFieldsPerTab).length !== Object.keys(state.totalFieldsPerTab).length) shouldUpdate = true;
        if (Object.keys(this.state.oldValues).length !== Object.keys(state.oldValues).length) shouldUpdate = true;
        if (!_.isEqual(this.props.form, props.form)) {
            shouldUpdate = true;
            shouldUpdateFields = true;
            this.setState({ isLoading: true });
            // //("FORM CHANGED");
            // this.buildFields();
        }
        if (this.state.activeTab !== state.activeTab) shouldUpdate = true;
        if (this.state.loadings !== state.loadings) {
            shouldUpdate = true;
            shouldUpdateFields = true;
        }
        if (this.state.isLoading !== state.isLoading) shouldUpdate = true;
        // if (this.props !== props) {
        //     shouldUpdate = true;
        // }
        // console.log("VALUES",state.formValues,"PROPS",props.values,_.isEqual(state.formValues,props.values));
        if (!_.isEqual(state.formValues, props.values)) {
            shouldUpdate = true;
        }
        if (!_.isEqual(this.props.parentValues, props.parentValues)) {
            shouldUpdate = true;
        }
        if (!_.isEqual(this.props.location, props.location)) {
            shouldUpdate = true;
        }
        // var differenceFields = diff(state.formValues, this.state.formValues);
        var fieldsNeedToRebuild: string[] = [];

        var differenceFields: any = this.lastChangedInput;
        //
        if (differenceFields !== undefined) {
            let uses = [];
            // ({ DIF: differenceFields.field });
            // alert(differenceFields);
            for (var k in this.uses) {
                if (this.uses[k].includes(differenceFields.field)) {
                    uses.push(k);
                }
            }
            if (uses.length > 0) {
                // fieldsNeedToRebuild = [...fieldsNeedToRebuild, ...uses];
                shouldUpdateFields = true;
                shouldUpdate = true;
            }
            //
            this.lastChangedInput = undefined;
        }
        if (shouldUpdateFields || this._forceUpdate === true) {
            if (this._forceUpdate === true) {
                shouldUpdate = true;
            }
            this.buildFields(this._forceUpdate === true ? [] : fieldsNeedToRebuild, state.formValues, state.priorityFormData);
            this._forceUpdate = false;
            this.hasChanged = [];
        }
        //
        if (shouldUpdate) {
            // console.log("UPDATING EVERTHING!");
            // alert("UPDATING EVERYTHING!")
        }
        return shouldUpdate;
    };

    public lastChangedInput: any = {};
    public fieldCache: any = {};

    setExternalFieldValue = (field: string, fieldValue: any) => {
        this._forceUpdate = true;
        if (field) {
            var cachedData = this.state.cachedData;
            //console.log({ cachedData });
            if (typeof fieldValue == "object") {
                cachedData[field] = [fieldValue];
            } else {
                cachedData[field] = fieldValue;
            }
            this.setState({ cachedData });
        }
        // alert("SETTING VALUE OF "+field+' TO '+fieldValue)
        this.setStateFieldValue(field, fieldValue, true);
    };

    setStateProperty = (fieldOrState: string | object, value: any = null) => {
        this._forceUpdate = true;
        if (value == null || typeof fieldOrState == "object") {
            this.setState(fieldOrState);
        } else {
            var obj: any = {};
            obj[fieldOrState] = value;
            this.setState(obj);
        }
    };

    setStateFieldValue = (field: string, fieldValue: any, external = false) => {
        if (this.state.formValues[field] !== fieldValue) {
            this._forceUpdate = true;
            this.lastChangedInput = { field, fieldValue };
            // console.log("FIELD ",,"FROM TO", field + " CHANGED VALUE TO " + fieldValue);
            var lastValues = this.state.formValues;
            if (
                fieldValue !== undefined &&
                fieldValue !== null &&
                typeof fieldValue === "string" &&
                this.state.formData[field] !== undefined &&
                this.state.formData[field].allowLowercase === undefined &&
                this.state.formData[field].type !== 'custom' &&
                this.state.formData[field].type !== "richtextbox"
            ) {
                fieldValue = fieldValue.toLocaleUpperCase();
            }
            lastValues[field] = fieldValue;
            this.setState({ formValues: lastValues });
            this.setState((value: any) => {
                var errors = { ...value.errors };
                if (Object.keys(errors).includes(field)) {
                    delete errors[field];
                }
                return { errors };
            });
        }
    };

    public cachedData: any = {};

    onChangeInput = (inputValue: any, name: string, cacheData: any = [], userAction = true) => {
        if (cacheData) {
            var cachedData = this.state.cachedData;
            cachedData[name] = cacheData;
            this.setState({ cachedData });
        }
        if (typeof inputValue == "string" && inputValue.length > 0 && userAction) {
            if (inputValue !== this.state.oldValues[name]) {
                if (this.props.onDirty) {
                    this.props.onDirty(name);
                }
                this.setState({ isFormDirty: true });
            }
        }
        this.setStateFieldValue(name, inputValue);
    };

    setFieldState = (fieldName: string, value: any) => {
        this._forceUpdate = true;
        //console.log({ fieldName, value });
        this.setState((oldState: any) => {
            var fieldStates = oldState.fieldStates;
            var oldFieldState = fieldStates[fieldName];
            if (!oldFieldState) {
                oldFieldState = {};
            }
            oldFieldState = value;
            fieldStates[fieldName] = oldFieldState;
            return { fieldStates };
        });
    };

    renderError = (fieldName: string) => {
        // return null;
        if (Object.keys(this.state.errors).includes(fieldName)) {
            return (
                <ErrorMessage show={true} placement={"bottomEnd"}>
                    <span dangerouslySetInnerHTML={{ __html: this.state.errors[fieldName] }}></span>
                </ErrorMessage>
            );
        }
        return null;
    };

    setFieldProperty = (field: string, property: string, value: any) => {
        this.setState((oldState: any) => {
            var priorityFormData = { ...oldState.priorityFormData };
            if (!priorityFormData[field]) {
                priorityFormData[field] = {};
            }
            priorityFormData[field][property] = value;
            return { priorityFormData };
        });
    };

    public lowestTextInputIndex = 1000;
    renderEachFieldType = (field: any, index: any) => {
        var entranceField = field;
        var type = field.type;
        var hidden: any = false;
        const tabs = this.props.form.$GLOBALS ? this.props.form.$GLOBALS.tabs : [];
        var defaultValue: any = this.state.oldValues[entranceField.name] !== undefined ? this.state.oldValues[entranceField.name] : undefined;

        if (field.hidden === true || (this.props.isEdit === true && field.canEdit !== true) || (this.props.readOnly === true && field.canRead !== true)) {
            hidden = true;
        }
        if (hidden === false && field.tabs !== null && field.tabs !== undefined && !field.tabs.includes(this.state.activeTab) && tabs && tabs.length > 0) {
            hidden = true;
        }
        if (hidden) {
            return null;
        }

        if (index < this.lowestTextInputIndex && type === "text") {
            this.lowestTextInputIndex = index;
        }

        var oldValue: any = this.state.formValues[entranceField.name];
        if (defaultValue === undefined && field.default !== undefined) {
            defaultValue = field.default;
            this.onChangeInput(defaultValue, field.name, [], false);
        }
        var eventName: any = _.startCase(_.camelCase(field.name)).split(" ").join("") + "Events";

        var instancedClass: any = {};
        var onEvents: any = {};
        if (field.password && defaultValue === undefined && this.props.isEdit) {
            defaultValue = "";
        }

        var errors = [];
        if (field.label === undefined) {
            errors.push("Esse campo não possui label!");
        }
        if (field.name.includes("fk") && field.type !== "select") {
            errors.push("Esse campo possui FK (" + field.name + ") mas é do tipo " + field.type + "!");
        }
        if (field.name.includes(this.props.form.$GLOBALS.prefix + "_" + this.props.form.$GLOBALS.prefix) && field.type !== 'checktreepicker') {
            errors.push("Esse campo possui o nome duplicado (" + field.name + "), isso irá causar problemas nas colunas futuramente!");
        }
        if (field.type === "select" && field.api === undefined && field.options === undefined) {
            errors.push("Esse campo é do tipo SELECT e não possui API nem OPTIONS!");
        }
        if (field.type === "select" && !field.name.includes("fk") && field.api !== undefined) {
            errors.push("Esse campo é do tipo SELECT mas não possui FK no nome, isso fará com que o relacionamento deste campo não funcione corretamente!");
        }
        if (field.type === "grid" && field.api === undefined) {
            errors.push("Esse campo é do tipo GRID mas não possui API");
        }

        if (this.state.loadings.includes(field.name)) {
            return (
                <FormGroup key={index} className={classNames("col-md-" + field.width)}>
                    <ControlLabel>
                        <div dangerouslySetInnerHTML={{ __html: field.label ? field.label : field.name }}></div>
                    </ControlLabel>
                    <Placeholder.Paragraph rows={2} rowMargin={8}></Placeholder.Paragraph>
                </FormGroup>
            );
        }

        var renderError: any = <ErrorPopover errors={errors} title="Esse campo não possui label!" />;
        try {
            if (Events) {
                var eventList: any = Events;
                var event: any = eventList[eventName];

                if (event) {
                    instancedClass = new event(field.name, this.state, this.setStateProperty, this.setFieldState, this.setExternalFieldValue, this.setFieldProperty, this.props.history.route, this.props.history.match, this.props);
                    for (let i in instancedClass) {
                        if (i === "render") {
                            onEvents[i] = instancedClass[i];
                        }
                        // if(i === 'onEvents') {
                        //     alert(JSON.stringify(onEvents[i]));
                        //     continue;
                        // }
                        if (i !== "render" && i !== "callback" && i !== "setGlobalState" && i !== "setState" && i !== "setValue" && i !== "setProperties") {

                            onEvents[i] = (value: any, ...props: any) => {
                                // console.log("HAS PARENT",this.props.parent);
                                // instancedClass[i].parent = this.props.parent
                                var output = instancedClass[i](value, this.setExternalFieldValue, this.setStateProperty, this.props.parentValues ?? this.state.formValues, ...props);

                                if (output) {
                                    return output;
                                }
                            };
                        }
                    }
                }
                const genericClass = this.genericEventHandler;
                for (let i in genericClass.onEvents) {
                    if (!onEvents[i]) {
                        let output = this.checkGenericEvent(i, field.name);
                        if (output) {
                            onEvents[i] = (value: any, ...props: any) => {
                                if (output) {
                                    output(value);
                                    return output;
                                }
                            };
                        }
                    }
                }
                // console.log(field.name, onEvents, genericClass);
            }
        } catch (ex) { }
        var formOverrides: any = {};
        if (onEvents["overrideConditions"]) {
            formOverrides = onEvents["overrideConditions"](field);
        }
        var outputHtmlField = null;

        try {
            switch (type) {
                case "custom":
                    var componentList: any = CustomComponents;
                    var builtComponent: any = null;
                    if (componentList[field.component]) {
                        builtComponent = componentList[field.component];

                        outputHtmlField = (
                            <FormGroup>
                                {/* <ControlLabel>
                                <div dangerouslySetInnerHTML={{ __html: (field.label ? field.label : field.name) + (field.description ?? "") }}></div>
                            </ControlLabel> */}
                                {React.createElement(builtComponent, {
                                    ...this.props,
                                    ...field,
                                    field: { ...field },
                                    error: this.renderError(field.name),
                                    customValues: this.state.formValues[field.name] ? this.state.formValues[field.name] : undefined,
                                    guid: this.guid,
                                    parent: this.props.parent,
                                    relation: this.props.form.$GLOBALS.table,
                                    onChange: (value: boolean) => {
                                        this.onChangeInput(value, field.name);
                                        if (onEvents["onChange"]) {
                                            onEvents["onChange"](value);
                                        }
                                        // if(onEvents)
                                    },
                                }) ?? "Oops..."}

                            </FormGroup>
                        );
                    }
                    break;
                case "checkbox":
                    var valueFieldCheck = field.checkedType !== undefined ? field.checkedType : oldValue;
                    outputHtmlField = (
                        <FormGroup className={classNames("checkbox")}>
                            <ControlLabel>
                                <div dangerouslySetInnerHTML={{ __html: (field.label ? field.label : field.name) + (field.description ?? "") }}></div>
                            </ControlLabel>
                            <Toggle
                                {...onEvents}
                                defaultChecked={oldValue ? oldValue : defaultValue}
                                checked={valueFieldCheck ? valueFieldCheck : defaultValue}
                                disabled={field.readonly || field.disabled}
                                onChange={(value: boolean) => {
                                    this.onChangeInput(value, field.name);
                                    if (onEvents["onChange"]) {
                                        onEvents["onChange"](value);
                                    }
                                    // if(onEvents)
                                }}></Toggle>
                            {this.renderError(field.name)}
                            {field.required && <HelpBlock>Obrigatório</HelpBlock>}
                        </FormGroup>
                    );
                    break;
                case "numeric":
                    outputHtmlField = (
                        <FormGroup>
                            <ControlLabel>
                                <div
                                    style={
                                        {
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            width: '95%'
                                        }
                                    }
                                >
                                    <div dangerouslySetInnerHTML={{ __html: (field.label ? field.label : field.name) + (field.description ?? "") }}></div>
                                    {field.columnInfo && (
                                        <Whisper speaker={
                                            <Popover title="Informacão">
                                                <div dangerouslySetInnerHTML={{ __html: (field.columnInfo ? field.columnInfo : "") }}></div>
                                            </Popover>
                                        } trigger={"hover"} placement="top">
                                            <i className="fas fa-info-circle"></i>
                                        </Whisper>
                                    )}
                                </div>
                                {field.password === true && this.props.isEdit ? (
                                    <small style={{ fontSize: 10, marginLeft: 6 }}> deixe em branco caso não deseje trocar a senha.</small>
                                ) : null}
                                {renderError}
                            </ControlLabel>
                            <div className="rs-form-control-wrapper">
                                <NumberInput
                                    maxLenght={field.maxlength}
                                    leftPadding={field.leftPadding}
                                    rightPadding={field.rightPadding}
                                    disabled={field.readonly || field.disabled}
                                    preffix={field.preffix}
                                    suffix={field.suffix}
                                    punctuation={field.punctuation}
                                    step={field.step}
                                    max={field.max}
                                    min={field.min}
                                    onBlur={(value: any) => {
                                        if (onEvents["onBlur"]) {
                                            onEvents["onBlur"](value);
                                        }
                                    }}
                                    value={this.state.formValues[field.name] ?? undefined}
                                    onChange={(value: string) => this.onChangeInput(value, field.name)}
                                />
                                {this.renderError(field.name)}
                            </div>
                            {field.required && <HelpBlock>Obrigatório</HelpBlock>}
                        </FormGroup>
                    );
                    break;
                case "datepicker":
                    outputHtmlField = (
                        <FormGroup>
                            <ControlLabel>
                                <div dangerouslySetInnerHTML={{ __html: (field.label ? field.label : field.name) + (field.description ?? "") }}></div>
                                {field.password === true && this.props.isEdit ? (
                                    <small style={{ fontSize: 10, marginLeft: 6 }}> deixe em branco caso não deseje trocar a senha.</small>
                                ) : null}
                                {renderError}
                            </ControlLabel>
                            <div className="rs-form-control-wrapper">
                                <DatePickerComponent
                                    value={this.state.formValues[field.name] ?? ''}
                                    onChange={(value: any) => {
                                        this.onChangeInput(value, field.name);
                                        if (onEvents["onChange"]) {
                                            onEvents["onChange"](value);
                                        }
                                    }}
                                    readonly={field.readonly ? true : false}
                                />
                                {this.renderError(field.name)}
                            </div>
                            {field.required && <HelpBlock>Obrigatório</HelpBlock>}
                        </FormGroup>
                    )
                    break;
                case "text":
                    outputHtmlField = (
                        <FormGroup>
                            <ControlLabel>
                                <div
                                    style={
                                        {
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }
                                    }
                                >
                                    <div dangerouslySetInnerHTML={{ __html: (field.label ? field.label : field.name) + (field.description ?? "") }}></div>
                                    {field.columnInfo && (
                                        <Whisper speaker={
                                            <Popover title="Informacão">
                                                <div dangerouslySetInnerHTML={{ __html: (field.columnInfo ? field.columnInfo : "") }}></div>
                                            </Popover>
                                        } trigger={"hover"} placement="top">
                                            <i className="fas fa-info-circle"></i>
                                        </Whisper>
                                    )}
                                </div>

                                {field.password === true && this.props.isEdit && field.name !== 'user_salary' ? (
                                    <small style={{ fontSize: 10, marginLeft: 6 }}> deixe em branco caso não deseje trocar a senha.</small>
                                ) : null}
                                {renderError}
                            </ControlLabel>
                            <div className="rs-form-control-wrapper">
                                <MaskInput
                                    autoFocus={index === this.lowestTextInputIndex}
                                    {...onEvents}
                                    allowLowercase={field.allowLowercase}
                                    disabled={field.readonly || field.disabled}
                                    isEdit={this.props.isEdit}
                                    password={field.password ? field.password.toString() : "false"}
                                    password_character={field.password_character}
                                    value={this.state.formValues[field.name] && field.password !== true ? this.state.formValues[field.name] : undefined}
                                    name={field.name}
                                    maxLength={field.maxlength ?? undefined}
                                    minLength={field.minlength ?? undefined}
                                    preffix={field.preffix}
                                    suffix={field.suffix}
                                    onChangeEnd={(value: string, e: any) => {
                                        this.onChangeInput(value, field.name);
                                        if (onEvents["onChange"]) {
                                            onEvents["onChange"](value);
                                        }
                                    }}
                                    required={field.password === true && this.props.isEdit ? false : field.required}
                                    regex={field.regex}
                                    mask={field.disabledMask == true ? undefined : field.mask}></MaskInput>
                                {this.renderError(field.name)}
                            </div>
                            {field.required && <HelpBlock>Obrigatório</HelpBlock>}
                        </FormGroup>
                    );
                    break;
                case "select":
                    let usesValues: any = {};
                    let useSearchValues: any = false;
                    if (field.api && field.api.uses) {
                        for (let i in field.api.uses) {
                            var use = field.api.uses[i];
                            //console.log({use});
                            if (use.type === "field" && use.field !== field.name) {
                                var value = this.state.formValues[use.field]; // || this.props.values[use.field];
                                if (value !== null) {
                                    usesValues[use.as] = value;
                                }
                            }
                            if (use.type === "value") {
                                useSearchValues = true
                                value = use.field;
                                //console.log({value})
                                usesValues[use.as] = value;
                            }
                        }
                        // console.log(usesValues);
                        // alert(JSON.stringify(usesValues));
                    }
                    var data: any[] = this.state.formData[field.name] && this.state.formData[field.name].options ? this.state.formData[field.name].options : [];

                    var relationName: any = null;
                    var displayLabel = (field.browseLabel?.length > 0 ? field.browseLabel : undefined) ?? field.displayLabel;
                    if (field.name.includes("fk") && defaultValue !== undefined && defaultValue !== null) {
                        relationName = field.name.split("fk_").pop().split("_id").shift();
                        // console.log("FELIPEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE:::::", this.state.oldValues);
                        if (this.state.oldValues[relationName]) {
                            defaultValue = this.state.oldValues[relationName][relationName + "_id"];
                            if (this.state.oldValues[relationName] && displayLabel) {
                                try {
                                    var label = displayLabel.split(".").reduce((o: any, i: any) => o[i], this.state.oldValues);
                                    if (label === null) {
                                        label = "NOT FOUND";
                                    }
                                    data = [{ value: defaultValue.toString().toLocaleUpperCase(), label }];
                                } catch (e: any) {
                                    data = [{ value: -1, label: "Whoops.." + e.message }];
                                    try {
                                        // console.log("EVALUATING",field,relationName,this.state.oldValues);
                                        const evalued = eval("var " + relationName + " = JSON.parse('" + JSON.stringify(this.state.oldValues[relationName]) + "');" + displayLabel);
                                        data = [{ value: defaultValue.toString().toLocaleUpperCase(), label: evalued }];
                                        // console.log(evalued);
                                    } catch (e) {
                                        // console.log(e);
                                        throw Error("Não foi possivel encontrar o caminho de " + displayLabel);
                                    }
                                }
                            }
                        }
                    }
                    var cachedData = _.map(this.state.cachedData[field.name] ? this.state.cachedData[field.name] : [], (item) => {
                        item.value = item.value.toString().toLocaleUpperCase();
                        return item;
                    });

                    var finalValue = oldValue ? oldValue : defaultValue;
                    if (finalValue) {
                        finalValue = finalValue.toString().toLocaleUpperCase();
                    }
                    if (finalValue === null) {
                        cachedData = [];
                    }
                    const availableOptions = _.map([...data, ...cachedData], (item: any) => {
                        item.value = item.value.toString().toLocaleUpperCase();
                        item.label = item.label.toString().toLocaleUpperCase();
                        return item;
                    });
                    //console.log("Use", usesValues)
                    // console.log('teste :::> ',field.advancedSearch)
                    // console.log("Field :::::> ",field.name);
                    outputHtmlField = (
                        <FormGroup>
                            <ControlLabel>
                                <div
                                    style={
                                        {
                                            display: "flex",
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            width: '95%'
                                        }
                                    }
                                >
                                    <div dangerouslySetInnerHTML={{ __html: (field.label ? field.label : field.name) + (field.description ?? "") }}></div>
                                    {field.columnInfo && (
                                        <Whisper speaker={
                                            <Popover title="Informacão">
                                                <div dangerouslySetInnerHTML={{ __html: (field.columnInfo ? field.columnInfo : "") }}></div>
                                            </Popover>
                                        } trigger={"hover"} placement="top">
                                            <i className="fas fa-info-circle"></i>
                                        </Whisper>
                                    )}
                                </div>
                                {renderError}
                            </ControlLabel>
                            <div className="rs-form-control-wrapper">
                                <AxiosSelectPicker
                                    {...field}
                                    readOnly={field.readonly}
                                    disabled={field.disabled}
                                    defaultValue={finalValue}
                                    value={this.state.formValues[field.name]}
                                    isEdit={this.props.isEdit}
                                    canEdit={field.canEdit}
                                    onFirstLoad={(data: any) => {
                                        this.setState((oldState: any) => {
                                            var cachedData = { ...oldState.cachedData };
                                            cachedData[field.name] = data;
                                            return { cachedData };
                                        });
                                    }}
                                    onChange={(value: string, e: any) => {
                                        this.onChangeInput(value ? value.toString() : value, field.name, e);
                                        //console.log("CHANGED", field.name);
                                        if (onEvents["onChange"]) {
                                            onEvents["onChange"](value ? value.toString() : value);
                                        }
                                        if (onEvents['onChangeSelect']) {
                                            onEvents["onChangeSelect"](e);
                                        }
                                    }}
                                    advanced={field.advancedSearch ?? undefined}
                                    advancedInclude={field.advancedInclude ?? undefined}
                                    searchable={field.searchable ?? undefined}
                                    uses={usesValues}
                                    searchValue={useSearchValues}
                                    shortcut={field.shortcut ?? undefined}
                                    displayLabel={field.displayLabel ?? undefined}
                                    api={field.api ? field.api : undefined}
                                    useActive={field?.useActive ? field?.useActive : undefined}
                                    options={field.options ? field.options : undefined}
                                    placeholder={"Selecione..."}
                                    data={availableOptions}></AxiosSelectPicker>
                                {this.renderError(field.name)}
                            </div>
                            {/* <div style={{whiteSpace:"normal",wordBreak:"break-all"}}>
                            {JSON.stringify(this.state.formValues[relationName])} <br />
                            - {diplayLabel}

                            </div> */}

                            {field.required && <HelpBlock>Obrigatório</HelpBlock>}
                        </FormGroup>
                    );
                    break;
                case "divider":
                    outputHtmlField = (
                        <Divider key={index}>
                            <h4>
                                <div dangerouslySetInnerHTML={{ __html: (field.label ? field.label : field.name) + (field.description ?? "") }}></div>
                                {renderError}
                            </h4>
                        </Divider>
                    );
                    break;
                case "grid":
                    if (!field.api) {
                        outputHtmlField = <div style={{ display: "flex" }}>Oops... {renderError}</div>;
                    } else {
                        outputHtmlField = (
                            <FormGroup key={index}>
                                <div className="rs-form-control-wrapper">
                                    {/* {console.log("aqui:: ",field)} */}
                                    <Grid
                                        {...onEvents}
                                        name={field.name}
                                        parentValues={this.state.formValues}
                                        parent={this.props.form}
                                        inline={field.inline === true}
                                        values={_.cloneDeep(oldValue ? oldValue : defaultValue)}
                                        canAdd={field.gridAdd === true}
                                        canDelete={field.gridDelete === true}
                                        label={field.label}
                                        formOverrides={formOverrides}
                                        canEdit={field.gridEdit === true}
                                        globals={this.props.form.$GLOBALS}
                                        readOnly={this.props.readOnly}
                                        draggable={field.draggable}
                                        // isEdit={this.props.isEdit}
                                        compact={field.compact}
                                        onChange={(value: any, cache: any) => {
                                            // alert("CHANGED");
                                            this.onChangeInput(value, field.name, cache);
                                            if (onEvents["onChange"]) {
                                                onEvents["onChange"](value);
                                            }
                                        }}
                                        actions={(rowData: any) => {
                                            if (onEvents["actions"]) {
                                                return onEvents["actions"](rowData);
                                            }
                                        }}
                                        onAdd={(value: any, index: any) => {
                                            if (onEvents["onAdd"]) {
                                                onEvents["onAdd"](value, index);
                                            }
                                        }}
                                        onEdit={(value: any, index: any) => {
                                            if (onEvents["onEdit"]) {
                                                onEvents["onEdit"](value, index);
                                            }
                                        }}
                                        onDelete={(value: any, index: any) => {
                                            if (onEvents["onDelete"]) {
                                                onEvents["onDelete"](value, index);
                                            }
                                        }}
                                        history={this.props.history}
                                        field={field}
                                    />
                                    {this.renderError(field.name)}
                                </div>
                            </FormGroup>
                        );
                    }
                    break;
                case "color":
                    outputHtmlField = (
                        <FormGroup>
                            <ControlLabel>
                                <div dangerouslySetInnerHTML={{ __html: (field.label ? field.label : field.name) + (field.description ?? "") }}></div>
                                {field.password === true && this.props.isEdit ? (
                                    <small style={{ fontSize: 10, marginLeft: 6 }}> deixe em branco caso não deseje trocar a senha.</small>
                                ) : null}
                                {renderError}
                            </ControlLabel>
                            <ColorPicker value={oldValue ? oldValue : defaultValue} onChange={(value: any, e: any) => this.onChangeInput(value, field.name)} />
                            {this.renderError(field.name)}
                        </FormGroup>
                    );
                    break;
                case "daypicker":
                    var oldDayValues = [];
                    for (var dayIndex = 1; dayIndex <= 31; dayIndex++) {
                        const fieldName = field.name + "_day" + dayIndex;
                        if (this.state.formValues[fieldName] === true) {
                            oldDayValues.push(dayIndex);
                        }
                    }
                    outputHtmlField = (
                        <FormGroup>
                            <ControlLabel>
                                <div dangerouslySetInnerHTML={{ __html: (field.label ? field.label : field.name) + (field.description ?? "") }}></div>
                                {field.password === true && this.props.isEdit ? (
                                    <small style={{ fontSize: 10, marginLeft: 6 }}> deixe em branco caso não deseje trocar a senha.</small>
                                ) : null}
                                {renderError}
                            </ControlLabel>
                            <DayPicker
                                values={oldDayValues}
                                days={31}
                                onChange={(values: any) => {
                                    // alert("CHANGED");
                                    for (var i = 1; i <= 31; i++) {
                                        this.onChangeInput(values.includes(i), field.name + "_day" + i);
                                    }
                                }}
                            />
                            {/* {JSON.stringify(oldDayValues)} */}
                        </FormGroup>
                    );
                    break;
                case "checktreepicker":
                    var parsedValue: any = [];
                    let usesValuesCheck: any = {};
                    let useSearchValuesCheck: any = false;
                    if (field.api && field.api.uses) {
                        for (let i in field.api.uses) {
                            var use = field.api.uses[i];
                            //console.log({use});
                            if (use.type === "field" && use.field !== field.name) {
                                var value = this.state.formValues[use.field]; // || this.props.values[use.field];
                                if (value !== null) {
                                    usesValuesCheck[use.as] = value;
                                }
                            }
                            if (use.type === "value") {
                                useSearchValuesCheck = true
                                value = use.field;
                                //console.log({value})
                                usesValuesCheck[use.as] = value;
                            }
                        }
                        // console.log(usesValuesCheck);
                        // alert(JSON.stringify(usesValuesCheck));
                    }
                    if (oldValue ? oldValue : defaultValue !== undefined) {
                        parsedValue = _.uniq(
                            (oldValue ? oldValue : defaultValue).map((item: any) => {
                                if (item[pluralize.singular(field.name) + "_value"]) {
                                    return item[pluralize.singular(field.name) + "_value"];
                                } else {
                                    return item ?? undefined;
                                }
                            }),
                        );
                    }
                    var cachedData = _.map(this.state.cachedData[field.name] ? this.state.cachedData[field.name] : [], (item) => {
                        return item;
                    });
                    // console.log('Field :::: ', field)
                    outputHtmlField = (
                        <FormGroup>
                            <ControlLabel>
                                <div dangerouslySetInnerHTML={{ __html: (field.label ? field.label : field.name) + (field.description ?? "") }}></div>
                                {field.password === true && this.props.isEdit ? (
                                    <small style={{ fontSize: 10, marginLeft: 6 }}> deixe em branco caso não deseje trocar a senha.</small>
                                ) : null}
                                {renderError}
                            </ControlLabel>
                            <div className="rs-form-control-wrapper">
                                <CheckTreePicker
                                    api={field.api ? field.api : undefined}
                                    disabled={field.disabled}
                                    options={field.options ? field.options : undefined}
                                    value={parsedValue}
                                    data={cachedData}
                                    uses={usesValuesCheck}
                                    useActive={field?.useActive ? field?.useActive : undefined}
                                    onChange={(value: any, e: any) => {
                                        this.onChangeInput(value, field.name, e);
                                        if (onEvents["onChange"]) {
                                            onEvents["onChange"](value ? value.toString() : value);
                                        }
                                    }}
                                    actions={(rowData: any) => {
                                        if (onEvents["actions"]) {
                                            return onEvents["actions"](rowData);
                                        }
                                    }}
                                    displayLabel={field.displayLabel ?? undefined}
                                />
                                {this.renderError(field.name)}
                            </div>
                            {field.required && <HelpBlock>Obrigatório</HelpBlock>}
                        </FormGroup>
                    );
                    break;
                case "list":
                    var listRelationName = field.name.split("_").pop();
                    if (this.state.oldValues[listRelationName] && field.displayLabel) {
                        try {
                            var values = [];
                            for (var i in this.state.oldValues[listRelationName]) {
                                label = field.displayLabel
                                    .split(listRelationName + ".")
                                    .join("")
                                    .split(".")
                                    .reduce((o: any, i: any) => o[i], this.state.oldValues[listRelationName][i]);
                                values.push(label);
                            }
                            if (label === null) {
                                label = "NOT FOUND";
                            }
                            oldValue = values;
                        } catch (e) { }
                    }
                    outputHtmlField = (
                        <FormGroup>
                            <ControlLabel>
                                <div dangerouslySetInnerHTML={{ __html: (field.label ? field.label : field.name) + (field.description ?? "") }}></div>
                                {field.password === true && this.props.isEdit ? (
                                    <small style={{ fontSize: 10, marginLeft: 6 }}> deixe em branco caso não deseje trocar a senha.</small>
                                ) : null}
                                {renderError}
                            </ControlLabel>
                            <ListInput
                                value={oldValue ? oldValue : defaultValue}
                                onChange={(value: any, e: any) => this.onChangeInput(value, field.name)}
                                api={field.api ? field.api : undefined}
                                options={field.options ? field.options : undefined}
                            />
                            {this.renderError(field.name)}
                        </FormGroup>
                    );
                    break;
                case "treepicker":
                    outputHtmlField = (
                        <FormGroup>
                            <ControlLabel>
                                <div dangerouslySetInnerHTML={{ __html: (field.label ? field.label : field.name) + (field.description ?? "") }}></div>
                                {field.password === true && this.props.isEdit ? (
                                    <small style={{ fontSize: 10, marginLeft: 6 }}> deixe em branco caso não deseje trocar a senha.</small>
                                ) : null}
                                {renderError}
                            </ControlLabel>
                            <DynamicTree />
                            {this.renderError(field.name)}
                        </FormGroup>
                    );
                    break;
                case "cascadepicker":
                    outputHtmlField = (
                        <FormGroup>
                            <ControlLabel>
                                <div dangerouslySetInnerHTML={{ __html: (field.label ? field.label : field.name) + (field.description ?? "") }}></div>
                                {field.password === true && this.props.isEdit ? (
                                    <small style={{ fontSize: 10, marginLeft: 6 }}> deixe em branco caso não deseje trocar a senha.</small>
                                ) : null}
                                {renderError}
                            </ControlLabel>
                            <AxiosCascadePicker
                                value={this.state.formValues[field.name] ?? []}
                                onChange={(value: any, e: any) => this.onChangeInput(value, field.name)}
                                field={field}
                                disabled={field.disabled}
                            />
                            {this.renderError(field.name)}
                            {/* {JSON.stringify(this.state.formValues[field.name])} */}
                        </FormGroup>
                    );
                    break;
                case "richtextbox":
                    let valueRickText = this.state.formValues[field.name];

                    if (valueRickText == null) {
                        valueRickText = ""
                    }

                    valueRickText = valueRickText.replace("<P>", "")
                    valueRickText = valueRickText.replace("</P>", "")

                    if ((valueRickText !== null) && (valueRickText !== undefined)) {
                        if (valueRickText) {
                            var newValueRickText = valueRickText;
                        }
                    }
                    outputHtmlField = (
                        <FormGroup>
                            <ControlLabel>
                                <div dangerouslySetInnerHTML={{ __html: (field.label ? field.label : field.name) + (field.description ?? "") }}></div>
                                {field.password === true && this.props.isEdit ? (
                                    <small style={{ fontSize: 10, marginLeft: 6 }}> deixe em branco caso não deseje trocar a senha.</small>
                                ) : null}
                                {renderError}
                            </ControlLabel>
                            <div className="rs-form-control-wrapper">
                                <WYSIWYG
                                    value={newValueRickText ? newValueRickText : this.state.formValues[field.name]}
                                    onChange={(value: any) => this.onChangeInput(value, field.name)}
                                    readOnly={field.readonly}
                                />
                                {this.renderError(field.name)}
                            </div>
                            {field.required && <HelpBlock>Obrigatório</HelpBlock>}
                        </FormGroup>
                    );
                    break;
                case "file":
                    outputHtmlField = (
                        <FormGroup>
                            <ControlLabel>
                                <div dangerouslySetInnerHTML={{ __html: (field.label ? field.label : field.name) + (field.description ?? "") }}></div>
                                {field.password === true && this.props.isEdit ? (
                                    <small style={{ fontSize: 10, marginLeft: 6 }}> deixe em branco caso não deseje trocar a senha.</small>
                                ) : null}
                                {renderError}
                            </ControlLabel>
                            {/* {JSON.stringify(field)} */}
                            <FileInput
                                onBeforeUpload={(value: any) => {
                                    if (onEvents["onBeforeUpload"]) {
                                        onEvents["onBeforeUpload"](value);
                                    }
                                }}
                                multiple={field.multifiles == true ? field.multifiles : false}
                                showQuestions={field.showQuestions == true ? true : false}
                                readonly={field.readonly == true ? true : false}
                                value={oldValue ? oldValue : defaultValue}
                                onChange={(value: any) => this.onChangeInput(value, field.name)}
                                guid={this.guid}
                                name={field.name}
                                relation={this.props.form.$GLOBALS.table}
                            />
                            {/* <hr />
                            <div>
                            {JSON.stringify(this.state.formValues[field.name])}
                            </div> */}
                            {this.renderError(field.name)}
                        </FormGroup>
                    );
                    break;
            }
        } catch (e: any) {
            console.error(e);
            return (
                <div id={field.name} className={classNames("col-" + field.width, "col-xs-12", "col-sm-" + field.width)} key={index}>
                    <FormGroup>
                        <ControlLabel>
                            <div dangerouslySetInnerHTML={{ __html: (field.label ? field.label : field.name) + (field.description ?? "") }}></div>
                        </ControlLabel>
                        <Message type={"error"} description={e.message + " - " + e}></Message>
                    </FormGroup>
                </div>
            );
        }

        /*{this.state.debugMode === true && <Message type={"info"} description={JSON.stringify(this.state.formValues[field.name])}></Message>}
                {this.state.debugMode === true && <Message className="rs-scrollable" type={"warning"} description={field.name}></Message>}
                {this.state.debugMode === true && <Message className="rs-scrollable" type={"success"} description={eventName}></Message>}
                {this.state.debugMode === true && <Message className="rs-scrollable" type={"info"} description={JSON.stringify(field.disabled)}></Message>}*/
        return (
            //@ts-ignore
            <div
                id={field.name}
                className={classNames("p-rel", "col-" + field.width, "col-xs-12", "col-sm-" + field.width)}
                style={{ display: hidden === true ? "none" : "block" }}
                key={index}>
                {outputHtmlField}
                {onEvents["onRender"] ? onEvents["onRender"](this.state.fieldStates[field.name] ?? {}) : null}
                {this.state.debugMode === true && <Message className="rs-scrollable" type={"success"} description={eventName}></Message>}
                {this.state.debugMode === true && <Message type={"info"} description={JSON.stringify(this.state.formValues[field.name])}></Message>}
            </div>
        );
    };

    onSubmit = (callback: Function | any = () => { }, errorCallback: Function | any = () => { }) => {
        try {
            // console.log('entrando no submit')
            var output: any = {};
            var errors: any = {};
            var passwords: string[] = [];
            var hasRequiredInTheSameTab = false;
            const activeFormData = { ...this.props.form, ...this.state.formData };

            for (let i in activeFormData) {
                var field = activeFormData[i];
                if (!field.name) continue;
                if (field.canAdd === false && this.props.isEdit === false) {
                    continue;
                }
                if (field.readOnly) {
                    continue;
                }
                if (field.password === true) {
                } else {
                    if (activeFormData[i].hidden !== true && activeFormData[i].disabled !== true) {
                        if (activeFormData[i].required) {
                            // if (i === "product_material_classes") {
                            //console.log(this.state.formValues[i]);
                            // }
                            if (
                                (this.state.formValues[i] === undefined || this.state.formValues[i] === null) &&
                                this.state.oldValues[i] === undefined &&
                                field.hidden !== true &&
                                field.disabled !== true
                            ) {
                                errors[i] = "Campo obrigatório!";
                            } else {
                                if (typeof this.state.formValues[i] === "string") {
                                    if (this.state.formValues[i].length <= 0) {
                                        errors[i] = "Campo obrigatório!";
                                    }
                                } else {
                                    if (typeof this.state.formValues[i] === "number") {
                                        if (this.state.formValues[i] === undefined || this.state.formValues[i] === null) {
                                            errors[i] = "Campo obrigatório!";
                                        }
                                        if (activeFormData[i]?.min > 0 && this.state.formValues[i] <= 0) {
                                            errors[i] = "Coloque um número maior que zero!";
                                        }
                                    } else if (_.isArray(this.state.formValues[i])) {
                                        if (this.state.formValues[i].length <= 0) {
                                            errors[i] = "Campo obrigatório!";
                                        }
                                    } else {
                                        errors[i] = "Campo obrigatório!";
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // console.log("output",output);
            for (let i in this.state.oldValues) {
                // console.log("key", this.props);
                if (this.props.name) {
                    var id = i.includes(pluralize.singular(this.props.name) + "_id");
                } else {
                    id = false;
                }
                if (this.state.oldValues[i] !== undefined && (Object.keys(this.props.form).includes(i) || i.includes("_day") || id)) {
                    output[i] = this.state.oldValues[i];
                }
            }
            for (let i in this.state.formValues) {
                if (this.state.formValues[i] !== undefined && (Object.keys(this.props.form).includes(i) || i.includes("_day"))) {
                    output[i] = this.state.formValues[i];
                }
            }
            for (let i in passwords) {
                delete output[passwords[i]];
            }
            for (let i in errors) {
                if (this.props.form[i].tabs && this.props.form[i].tabs.includes(this.state.activeTab)) {
                    hasRequiredInTheSameTab = true;
                    // alert("EXISTE OBRIGATORIO NA ABA");
                    break;
                }
            }
            //console.warn("ERROS", errors, Object.keys(errors).length === 0);
            this.setState({ errors, resultValues: output });
            var lowestTabIndex = 9999;
            var lowestTab = null;
            const tabsIndex = _.map(this.props.form.$GLOBALS.tabs ?? {}, (item) => {
                return item.key;
            });

            // (tabsIndex);
            if (errors) {
                var firstField = Object.keys(errors)[0];
                if (firstField) {
                    var field = this.props.form[firstField];
                    var tabs = field.tabs;
                    if (tabs && field.type !== "grid") {
                        var firstTab = tabs[0];
                        if (firstTab) {
                            if (lowestTabIndex > tabsIndex.indexOf(firstTab)) {
                                lowestTabIndex = tabsIndex.indexOf(firstTab);
                                lowestTab = firstTab;
                            }
                        }
                    }
                }
            }
            //alert(JSON.stringify(errors));
            //alert("HAS REQ "+(hasRequiredInTheSameTab ? 'S':'N') + ' TO '+lowestTab)
            if (lowestTab && hasRequiredInTheSameTab === false) {
                this.selectTab(lowestTab);
            }
            if (Object.keys(errors).length === 0 && this.props.onSubmit) {
                if (!output["guid"]) {
                    output["guid"] = this.guid;
                }
                const primaryKey = this.props.form.$GLOBALS.prefix + "_id";
                this.setState({ isLoading: true });
                for (var i in output) {
                    if (typeof output[i] == "object" && output[i]?.value) {
                        output[i] = output[i].value;
                    }
                    if (i == "psm_review_logs") {
                        delete output[i];
                    }
                    if (i == "psm_change_logs") {
                        delete output[i];
                    }
                }
                axios
                    .post("/api/v1/" + this.state.crud + "/validate", { id: this.state.formValues[primaryKey] ?? this.guid, data: output, parent: this.props.parentValues ?? undefined })
                    .then((res) => {
                        //console.log("VALIDATE", Object.keys(res.data.errors).length);


                        if (res && res.data && Object.keys(res.data.errors).length > 0) {
                            const errors: any = {};
                            const alertErrors: any = [];
                            // console.log("ISOBJECT",_.isArray(res.data.errors))
                            if (_.isObject(res.data.errors) && _.isArray(res.data.errors) === false) {
                                for (const field in res.data.errors) {
                                    errors[field] = res.data.errors[field];
                                }
                            } else {
                                for (const error of res.data.errors) {
                                    if (typeof error == "object") {
                                        errors[error.field] = error.text;
                                    } else {
                                        alertErrors.push(error);
                                    }
                                }
                            }
                            if (this.state.crud === 'boxes') {
                                let labels: string = "";
                                for (const item of alertErrors) {
                                    labels += `> ${item}, `;
                                }
                                labels = labels.slice(0, -2)
                                Notification.error({
                                    title: "Campos obrigatorios para avançar situação!",
                                    description: labels,
                                    placement: "bottomEnd",
                                    duration: 600000,
                                });
                                this.setState({ loading: false });

                            } else {
                                for (var i in alertErrors) {

                                    Notification.error({
                                        title: "Erro!",
                                        description: alertErrors[i],
                                        placement: "bottomEnd",
                                        duration: 9000,
                                    });

                                }
                            }
                            // console.log("SET", errors);

                            this.setState({ errors }, () => {
                                try {
                                    if (_.isObject(res.data.errors) && _.isArray(res.data.errors) === false) {
                                        var lowestTabIndex = 9999;
                                        var lowestTab = null;
                                        if (errors) {
                                            var firstField = Object.keys(errors)[0];
                                            if (firstField) {
                                                var field = this.props.form[firstField];
                                                var tabs = field.tabs;
                                                if (tabs && field.type !== "grid") {
                                                    var firstTab = tabs[0];
                                                    if (firstTab) {
                                                        if (lowestTabIndex > tabsIndex.indexOf(firstTab)) {
                                                            lowestTabIndex = tabsIndex.indexOf(firstTab);
                                                            lowestTab = firstTab;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        this.selectTab(lowestTab, () => { });
                                    }
                                } catch (e) { }
                            });
                            // this.setState({ errors },() => {
                            //     var lowestTabIndex = 9999;

                            // if (lowestTab && hasRequiredInTheSameTab === false) {

                            // }
                            // });
                        } else {
                            if (this.props.onSubmit) {
                                if (this.props.addCallback) {
                                    this.props.addCallback(callback, errorCallback);
                                }
                                this.props.onSubmit(output, this.guid, this.state.cachedData);
                            }
                        }
                    })
                    .catch((res) => {
                        console.error(res);
                        if (this.props.onSubmit) {
                            if (this.props.addCallback) {
                                this.props.addCallback(callback, errorCallback);
                            }
                            this.props.onSubmit(output, this.guid, this.state.cachedData);
                        }
                    })
                    .finally(() => {
                        this.setState({ isLoading: false });
                    });
            }
        } catch (e) {
            console.log('Error :::> ', e)
        }
    };

    public method: "edit" | "add" | "read" = this.props.isEdit ? "edit" : this.props.readOnly ? "read" : "add";

    // public method = "";

    renderSubmitButton = () => {
        // if (this.props.readOnly === true) {
        if (this.props.readOnly === true) {
            if (!this.props.isModal) {
                return null;
            }
        }
        if (this.props.submitButton !== undefined) {
            return this.props.submitButton(this.onSubmit);
        }
        // console.log('Props ::: ', this.props.form);
        let prefixForm: string = this.props.form.$GLOBALS.table === 'dibis' ? 'dibi' : this.props.form.$GLOBALS.prefix;
        const customFooterEventsName = _.upperFirst(_.camelCase(prefixForm + "FooterEvents"));
        const events: any = Events;
        const event = events[customFooterEventsName];
        var instancedClass: any = null;
        if (event) {
            instancedClass = new event(this.method + "_footer", this.state, this.setStateProperty, this.setFieldState, this.setExternalFieldValue);
        }
        const eventName = _.upperFirst(pluralize.singular(_.camelCase(this.state.crud))) + "Events";
        const eventHolder: any = Events;
        var showSave = true;
        // alert(eventName);
        if (eventHolder[eventName]) {
            const event = eventHolder[eventName];
            const instancedEvent = new event(
                null,
                this.state,
                this.setStateProperty,
                this.setFieldState,
                this.setExternalFieldValue,
                this.setFieldProperty,
                this.props.history,
                this.props.match,
                this.props,
            );

            if (instancedEvent["settings"]) {
                showSave = instancedEvent["settings"]["saveButton"] ?? true;
            }
        }
        let paramnsCrud = this.props.match.params.crud;
        if (paramnsCrud.includes('-')) {
            paramnsCrud = paramnsCrud.split('-').join('_');
        }
        // console.log('istanced class :: ', instancedClass);
        return (
            <div className="col-md-12 mt-4 d-flex" style={{ marginBottom: 10 }}>
                {((this.props.location.pathname !== "/dashboard/configuracoes/developments") && (!this.props.location.pathname.includes('transfer_requests'))
                    && (!this.props.location.pathname.includes('reports'))
                    && (!this.props.location.pathname.includes('roles'))
                    && (!this.props.location.pathname.includes('functions'))
                    && (!this.props.location.pathname.includes('users')))
                    && (<>
                        {(showSave == true || this.method == "add") && (
                            <>
                                <Permission name={(paramnsCrud + "-browse")}>
                                    <Button color="green" onClick={this.onSubmit}>
                                        <i className="fas fa-fw fa-save mr-2"></i>
                                        Gravar
                                    </Button> 
                                </Permission>
                            </>
                        )}
                    </>)}

                {/* Gravar apenas para users */}
                {/* {((this.props.location.pathname !== "/dashboard/configuracoes/developments") && (!this.props.location.pathname.includes('transfer_requests'))
                    && (!this.props.location.pathname.includes('reports'))
                    && (!this.props.location.pathname.includes('roles'))
                    && (this.props.location.pathname.includes('users')))
                    && (<>
                        {(showSave == true || this.method == "add") && (
                            <>
                                <Permission name={(paramnsCrud + "-browse")}>
                                    {(this.context.data?.user_id === 17 || this.context.data?.role?.role_id === 2 && (<>
                                        <Button color="green" onClick={this.onSubmit}>
                                            <i className="fas fa-fw fa-save mr-2"></i>
                                            Gravar
                                        </Button>
                                    </>))}

                                    {(this.context.data?.user_id !== 17 && this.context.data?.role?.role_id !== 2 && (<>
                                        <Button color="green" onClick={this.onSubmit}>
                                            <i className="fas fa-fw fa-save mr-2"></i>
                                            Gravar
                                        </Button>
                                    </>))}
                                </Permission>
                            </>
                        )}
                    </>)} */}

                {((this.props.location.pathname !== "/dashboard/configuracoes/developments") &&
                    (!this.props.location.pathname.includes('transfer_requests')) &&
                    (!this.props.location.pathname.includes('reports')) &&
                    (!this.props.location.pathname.includes('roles')) &&
                    (this.props.location.pathname.includes('users')))
                    && (showSave === true || this.method === "add") && (
                        <Permission name={`${paramnsCrud}-browse`}>
                            {
                            /* Retirei esta validação também, pois não está fazendo contexto, de acordo com a regra ela só será visivel para a NEC e Usuários Administradores
                               {(this.context.data?.user_id === UserPermission.USER_MASTER_NEC || this.context.data?.role?.role_is_system_manager === true) && (  
                            */
                            }
                                <Button color="green" onClick={this.onSubmit}>
                                    <i className="fas fa-fw fa-save mr-2"></i>
                                    Gravar
                                </Button>
                        </Permission>
                    )
                }

                {/* Gravar apenas para function */}
                {((this.props.location.pathname !== "/dashboard/configuracoes/developments") && (!this.props.location.pathname.includes('transfer_requests'))
                    && (!this.props.location.pathname.includes('reports'))
                    && (!this.props.location.pathname.includes('roles'))
                    && (this.props.location.pathname.includes('function')))
                    && (<>
                        {(showSave == true || this.method == "add") && (
                            <>
                                <Permission name={(paramnsCrud + "-browse")}>
                                    {/* 
                                        ALTEREI ESTA VALIDAÇÃO, POIS NA BRASMEG ESTÁ CONFLITANDO E NÃO ENCONTREI RAZÃO LÓGICA PARA MANTER ESSA VALIDAÇÃO
                                        {(this.context.data?.user_id === UserPermission.USER_MASTER_NEC && this.props.values.function_id !== 1 && this.props.values.function_id !== 2 && this.props.values.function_id !== 3 && this.props.values.function_id !== 4 && (<></> 
                                    */}
                                    {/* {console.log("Gravar para function", this) } */}
                                    {(this.context.data?.user_id === UserPermission.USER_MASTER_NEC || this.props.values.function_active == true && (
                                        <>
                                        <Button color="green" onClick={this.onSubmit}>
                                            <i className="fas fa-fw fa-save mr-2"></i>
                                            Gravar
                                        </Button>
                                        </>))}
                                </Permission>
                            </>
                        )}
                    </>)}

                {/* Gravar apenas para roles */}
                {((this.props.location.pathname !== "/dashboard/configuracoes/developments") && (!this.props.location.pathname.includes('transfer_requests'))
                    && (!this.props.location.pathname.includes('reports'))
                    && (this.props.location.pathname.includes('roles')))
                    && (<>
                        {(showSave == true || this.method == "add") && (
                            <>
                                <Permission name={(paramnsCrud + "-browse")}>
                                    {(this.context.data?.user_id === UserPermission.USER_MASTER_NEC && (<>
                                        <Button color="green" onClick={this.onSubmit}>
                                            <i className="fas fa-fw fa-save mr-2"></i>
                                            Gravar
                                        </Button>
                                    </>))}

                                    {(this.context.data?.user_id !== UserPermission.USER_MASTER_NEC && this.context.data?.role?.role_id !== RolePermissions.MASTER && (<>
                                        <Button color="green" onClick={this.onSubmit}>
                                            <i className="fas fa-fw fa-save mr-2"></i>
                                            Gravar
                                        </Button>
                                    </>))}
                                </Permission>
                            </>
                        )}
                    </>)}
                {this.method === "edit" ? (instancedClass ? (instancedClass["onRenderEditFooter"] ? instancedClass["onRenderEditFooter"](this.onSubmit) : null) : null) : null}
                {this.method === "add" ? (instancedClass ? (instancedClass["onRenderAddFooter"] ? instancedClass["onRenderAddFooter"](this.onSubmit) : null) : null) : null}
                {this.method === "read" ? (instancedClass ? (instancedClass["onRenderReadFooter"] ? instancedClass["onRenderReadFooter"](this.onSubmit) : null) : null) : null}
                {(!this.props.location.pathname.includes('graphics')) && (!this.props.location.pathname.includes('reports') && (!this.props.location.pathname.includes('integration'))) && (<>
                    <Permission name="debug">
                        <Button color={this.state.debugMode ? "violet" : undefined} onClick={() => this.setState({ debugMode: !this.state.debugMode })} className="ml-4">
                            <i className="fas fa-fw fa-bug"></i> {this.state.debugMode ? "Desativar" : "Ativar"} DEBUG
                        </Button>
                    </Permission>
                </>)}
            </div>
        );
    };
    uuidv4() {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    public guid = this.props.values?.guid ?? this.uuidv4();

    selectTab = async (activeTab: string, callback: any = function () { }) => {
        this.setState({ activeTab }, callback);
        if ((this.props.match.params.crud).includes("labs") || (this.props.match.params.crud).includes("layout_")) {
            //delcio            
            var { tabsLoaded } = this.state
            if (this.props.match.params.id) {
                const checkTab = await this.props.onCheckTab(activeTab)
                if (checkTab) {
                    this.setState({ isLoading: true })
                    axios.get("/api/v1/" + this.props.form.$GLOBALS.table + "/" + this.props.match.params.id + "?tab=" + activeTab).then((res) => {
                        const data = res.data.item
                        for (var i in data) {
                            this.setState({ formValues: { ...this.props.values, ...this.state.formValues, [i]: data[i], guid: this.guid } })
                        }
                        this.props.onChangeTab(this.state?.formValues)
                    }).catch((e) => { }).finally(() => {
                        this.setState({ isLoading: false })
                    })

                } else {
                    this.setState({ isLoading: false })
                }
                tabsLoaded.push(activeTab)
            }
        }
        // alert("MUDANDO PARA ABA " + JSON.stringify(this.state.tabsLoaded))
        this.props.history.replace(this.props.history.location.pathname + "?" + qs.stringify({ tab: activeTab }));
    };



    renderTabs = () => {
        const activeForm = { ...this.props.form, ...this.state.formData };
        // console.log({activeForm});
        const tabs = activeForm.$GLOBALS ? activeForm.$GLOBALS.tabs : [];
        if ((tabs?.length <= 0 && this.state.customTabs?.length <= 0) || tabs === undefined) {
            return (
                <Panel style={{ background: "white", width: "100%", overflow: "visible" }} bordered>
                    {this.renderContent()}
                </Panel>
            );
        } else {
            const steps = [];
            //////
            for (let i in tabs) {
                if (this.state.totalFieldsPerTab[tabs[i].key] && this.state.totalFieldsPerTab[tabs[i].key].length > 0) {
                    steps.push(
                        <Nav.Item onSelect={() => this.selectTab(tabs[i].key)} eventKey={tabs[i].key} key={i}>
                            {tabs[i].label}
                        </Nav.Item>,
                    );
                }
            }
            for (let i in this.state.customTabs) {
                const customTab = this.state.customTabs[i];

                steps.push(
                    <Nav.Item onSelect={() => this.selectTab(customTab.key)} eventKey={customTab.key} key={i}>
                        {customTab.label}
                    </Nav.Item>,
                );
            }
            return (
                <>
                    <Navbar className="col-md-12 pill-tabs">
                        <Navbar.Body>
                            <Nav activeKey={this.state.activeTab}>{steps}</Nav>
                        </Navbar.Body>
                    </Navbar>
                    <Panel style={{ background: "white", width: "100%" }} bordered>
                        {this.renderContent()}
                    </Panel>
                </>
            );
        }
    };

    backToTop = () => {
        window.scrollTo(0, 0);
    }

    renderContent() {
        if (this.state.isLoading === true) {
            return <Placeholder.Paragraph></Placeholder.Paragraph>;
        }
        const GLOBALS = this.props.form.$GLOBALS;
        if (!GLOBALS) {
            return <Placeholder.Paragraph></Placeholder.Paragraph>;
        }
        try {
            if (this.props.isModal) {
                return (
                    <form className="rs-form rs-form-vertical rs-form-fixed-width" autoComplete={"false"}>
                        <Modal.Header closeButton={false} className="mb-4">
                            <h3> {GLOBALS.singular_name ?? "Sem titulo"}</h3>
                        </Modal.Header>
                        <Modal.Body className="container m-0">
                            <fieldset disabled={this.props.readOnly === true} className="row">
                                {/* <input id="username" type="text" name="fakeusernameremembered" style={{ display: "none" }} />
                                <input id="password" type="password" name="fakepasswordremembered" style={{ display: "none" }} /> */}
                                {this.renderFields()}
                            </fieldset>
                        </Modal.Body>
                        <Modal.Footer className="mt-4">{this.renderSubmitButton()}</Modal.Footer>
                    </form>
                );
            }
            const customTab = _.find(this.state.customTabs ?? [], { key: this.state.activeTab });
            const isInCustomTab = customTab !== undefined;
            return (<>
                <button onClick={() => this.backToTop()} className="back-to-top"><i className="fas fa-angle-double-up"></i></button>
                <form className="rs-form rs-form-vertical rs-form-fixed-width" autoComplete={"false"}>
                    <fieldset >
                        <div className="row">
                            {/* <input id="username" type="text" name="fakeusernameremembered" style={{ display: "none" }} />
                            <input id="password" type="password" name="fakepasswordremembered" style={{ display: "none" }} /> */}
                            {isInCustomTab == true && customTab.jsxComponent}
                            {isInCustomTab == false && this.renderFields()}

                            {this.renderSubmitButton()}
                            {this.state.debugMode === true && <Message type="info" description={JSON.stringify(this.state.formValues)}></Message>}
                        </div>
                    </fieldset>
                </form>
            </>);
        } catch (e: any) {
            return (
                <div className="row">
                    <div className="col-md-12">
                        <Message type="error" description={e.message} />
                    </div>
                </div>
            );
        }
    }


    public starterValues = _.clone(this.props.values);


    filterOnTopFields = () => {
        return _.filter(this.state.formData, { showOnTop: true });
    };

    renderOnTopFields = () => {
        //console.log("Novo teste", this.starterValues)
        const fields = this.filterOnTopFields();

        // console.log("Olha",fields);
        const output = _.map(_.sortBy(fields, "showOnTopOrder"), (item, index: any) => {
            const field = item;

            var value = null;
            // var data = null;
            if (typeof this.starterValues[item.name] == "string") {
                value = this.starterValues[item.name]?.length ? this.starterValues[item.name] : "";
            }
            if (typeof this.starterValues[item.name] == "boolean") {
                value = this.starterValues[item.name];
            }

            if (item.type === "select") {
                if (item.options) {
                    value =
                        _.find(item.options, (listItem) => {
                            // console.log("FILTERING", listItem, this.starterValues[item.name]);
                            return listItem.value === this.starterValues[item.name] || listItem.value?.toString() === this.starterValues[item.name]?.toString();
                        })?.label ?? "Nenhum";
                } else {
                    var relationName: any = null;

                    var diplayLabel = (field.browseLabel?.length > 0 ? field.browseLabel : undefined) ?? field.displayLabel;

                    if (field.name.includes("fk")) {
                        // console.log("FIEEEELD:::: ", this.props.values);
                        relationName = field.name.split("fk_").pop().split("_id").shift();

                        // console.log(relationName);
                        // Alterações no props Mateus
                        // console.log("Olha o relation", relationName)
                        //console.log("Olha meu teste de valor",this.starterValues)

                        // props Mateus

                        if (this.starterValues[relationName]) {
                            // console.log("FELIPEEEEEEEEEEEEEEE:::", this.starterValues[relationName]);
                            if (this.starterValues[relationName] && diplayLabel) {
                                try {

                                    //console.log("Starter valor ::::: ",this.starterValues)
                                    var label = diplayLabel.split(".").reduce((o: any, i: any) => o[i], this.starterValues);
                                    //console.log("Meu label", label)

                                    if (label === null) {
                                        label = "NOT FOUND";
                                    }
                                    value = label;
                                    // data = { value: this.starterValues[field.name].toString().toLocaleUpperCase(), label };
                                } catch (e: any) {
                                    value = "Whoops.." + e.message;
                                    // data = { value: -1, label: "Whoops.." + e.message };
                                    // throw Error("Não foi possivel encontrar o caminho de " + diplayLabel);
                                }
                            } else {
                                value = "Não existe"
                            }
                        } else {
                            value = "teste";
                        }
                    }
                    value = _.find(this.state.cachedData[item.name], { value: this.starterValues[item.name]?.toString() })?.label ?? "Nenhum";
                    // value = _.find(this.state.cachedData[item.name],{value: value?.toString()});
                }
            }
            return (
                //Mateus (aqui encontra a div e classe que é responsável pelo cabecalho)
                <div key={index} className={classNames("col-md-2")} style={{ fontSize: "12px" }}>


                    <ControlLabel>
                        <span dangerouslySetInnerHTML={{ __html: item?.label ?? item?.name }}></span>

                    </ControlLabel>
                    {item.type !== "checkbox" && <MaskInput readonly mask={item.mask ?? undefined} value={value} />}
                    {item.type === "checkbox" && <Toggle readonly checked={value} />}
                    {/* {JSON.stringify(this.starterValues[item.name])} */}
                    {/* {JSON.stringify(item.options ?? [])} */}
                    {/* {JSON.stringify(value)} */}
                    {/* {JSON.stringify(this.state.cachedData[item.name])} */}
                    {/* {JSON.stringify(this.starterValues[item.name])}
                    {JSON.stringify(this.state.cachedData[item.name])} */}
                </div>
            );
        });
        return <div className="row">{output}</div>;
    };



    render() {
        return (
            <>
                {(this.props.isEdit === true || this.props.readOnly === true) && this.filterOnTopFields().length > 0 && (
                    <Panel bordered className="on-top rs-no-body mb-4 w-50">
                        {this.renderOnTopFields()}
                    </Panel>
                )}
                {/* botão back-to-tp */}

                {this.renderTabs()}
                {/* {this.state.crud} */}
                {/* {this.props.isEdit ? 'S':'N'} */}
                {/* {this.props.} */}
                {/* {this.guid} */}
                {/* <hr />
                {JSON.stringify(this.state.priorityFormData)}
                <hr />
                {JSON.stringify(this.state.formData["developer_string"])} */}
                {/* <pre> */}
                {/* {JSON.stringify(this.state.formData,null,2)} */}
                {/* </pre> */}
                {/* {this.state.guid} */}
                {/* {JSON.stringify(this.uses)} */}
                {/* {JSON.stringify(this.state.fieldStates)} */}
                {/* {JSON.stringify(this.state.loadings)} */}
                {/* {JSON.stringify(this.state.errors)} */}
            </>
        );
    }
}

export default withRouter(Formalize);