import React from 'react';
import styles from './styles/ChecklistMap.module.css'
import { Button, Modal, Panel } from "rsuite";
import { MapComponent } from './leafletMap/LeafMapComponent';
import { MethodsUtils } from '../../../../core/utils/MethodsUtils';
import iconMap from './assets/mark.png';
import iconAccuracy from './assets/accuracy.png'

export default class ChecklistMap extends React.Component<any> {
    
   state = {
        useMapActive: false,
    };

    handleClickViewMap = () => {
        this.setState({ useMapActive: true });
    };

    handleCloseModal = () => {
        this.setState({ useMapActive: false });
    };

    render() {
        const { useMapActive } = this.state;
        const {
            filled_checklist_lat,
            filled_checklist_long,
            filled_checklist_address,
            filled_checklist_geo_accuracy,
        } = this.props.values;

        return (
            <>
                <span className={styles['label']}>Endereço</span>
                    <a href="#" onClick={this.handleClickViewMap}>
                        <div className={styles['geolocation-address']}>
                                Geolocalização: {filled_checklist_address}
                        </div>
                    </a>
                <span className={styles['label font-12']}>*Clique na Geolocalização para visualizar o mapa</span>

                <Modal
                    show={useMapActive}
                    onHide={this.handleCloseModal}
                    size="lg"
                    backdrop="static"
                    onExit={this.handleCloseModal}
                    keyboard={true}>
                    <Modal.Header>
                        <Modal.Title>Detalhes da Geolocalização</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <div className={styles['title-info-map']}>
                                Latitude: {filled_checklist_lat} - Longitude: {filled_checklist_long} - <strong> Precisão do GPS: </strong>{filled_checklist_geo_accuracy}
                            </div>
                        <div className={styles['map-container']}>
                            <MapComponent
                                latitude={Number(filled_checklist_lat)}
                                longitude={Number(filled_checklist_long)}
                                accuracy={MethodsUtils.extractNumberFromString(filled_checklist_geo_accuracy)}
                                zoomMax={19}
                                zoom={13}
                                zoomMin={8}
                                scrollWheelZoom={true}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className={styles['imgLegend']}>
                            <img src={iconAccuracy} alt="Mapa" />&nbsp; Zona de Precisão do GPS
                         </div>
                        <Button color='red'
                            onClick={this.handleCloseModal}>Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}