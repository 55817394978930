import { orderBy } from "lodash";
import { Event } from "../Event";

export class FilledChecklistEvents extends Event {

    public onLoad = async () => {
        
        if (this.globalProps.match.url.includes("/view")){
            this.setField("filled_checklist_checklist", {hidden: false})

            if (!this.globalProps.values.filled_checklist_request_plate) {
                this.setField("filled_checklist_vehicle", {hidden: true})
                this.setField("filled_checklist_request_plate", {hidden: false, width:3, order:53})
            }

            if (this.globalProps.values.filled_checklist_contracted_driver) {
                this.setField("filled_checklist_current_base_name", {hidden: true})
                this.setField("filled_checklist_current_company_name", {hidden: true})
            }
            
            const viewDataAux: boolean = this.globalProps.values.filled_checklist_start && this.globalProps.values.filled_checklist_finish
          
            if (viewDataAux) {
                this.setField("filled_checklist_start", {hidden: false})
                this.setField("filled_checklist_finish", {hidden: false})
                this.setField("filled_checklist_map", {hidden: false})
            } else {
                this.setField("filled_checklist_name", {width: 8})
            }
        } 
    }

}