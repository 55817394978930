import React from "react";
import "./styles/modalOverlay.css"; 

type Props = {
    isVisible: boolean;
    message: string;
    iconClassName?: string;
}

const ModalOverlay: React.FC<Props> = ({ isVisible, message, iconClassName }) => {
    if (!isVisible) return null;

    return (
        <div className="fullscreen-overlay">
            <div className="fullscreen-overlay-content">
                <i className={`fullscreen-overlay-icon ${iconClassName}`}></i>
                <h4>{message}</h4>
            </div>
        </div>
    );
};

export default ModalOverlay;