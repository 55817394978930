import { ArrowLeftLine, Reload, ViewsUnauthorize } from "@rsuite/icons";
import styles from "./styles.module.scss";
import React from "react";
import { Button, Icon, Input, InputGroup, Notification, Panel, SelectPicker } from "rsuite";
import axios from "axios";
import { parseCookies, setCookie } from "nookies";
import { MethodsUtils } from "../../../../core/utils/MethodsUtils";
import { UseGeolocation } from "../../../../core/utils/findGeolocation";
import { IGeolocation } from "../../../../core/interfaces/interfaces";


type Mark = {
    value: number | string;
    label: string;
}

type ChecklistTypes = {
    value: number | string;
    label: string;
}

type ChecklistTable = {
    value: number | string;
    label: string;
    requestPlate: boolean
}

type Vehicle = {
    id: number;
    plate: string;
    markName: string;
    model: string;
}

type User = {
    id: number;
    name: string;
    cpfCnpj: string;
    userThird: boolean;
}

interface RegisterChecklistPageState {
    checkListType: number;
    hiddenVehicleField: boolean;
    hiddenFoundVehicle: boolean;
    hiddenNotFoundVehicle: boolean;
    hiddenTableChecklist: boolean;
    hiddenSelectUser: boolean;
    checklistTable: number | null;
    plate: string;
    readonlySelectType: boolean;
    readonlyInputPlate: boolean;
    readonlyNewPlate: boolean;
    readonlyNewMark: boolean;
    readonlyNewModel: boolean;
    readonlyTableChecklist: boolean;
    newPlate: string;
    newMark: string;
    newModel: string;
    marks: Mark[],
    driver: string;
    currentKM: number;
    checklistTypes: ChecklistTypes[];
    foundVehicle: Vehicle | null;
    checklistTables: ChecklistTable[];
    hiddenFinishToolBar: boolean;
    userObj: User | null;
    requestPlate: boolean,
    checklistTableName: string
}

interface CustomInputGroupWidthButtonProps {
    id: string;
    placeholder: string;
    onSearch: () => void;
    onPlateChange: (value: string) => void;
    plate: string;  
    readonly: boolean;
}


const CustomInputGroupWidthButton: React.FC<CustomInputGroupWidthButtonProps> = ({ id, placeholder, onSearch, onPlateChange, plate, readonly, ...props }) => {

    const handleInputChange = (value: string) => {
        const formattedValue = MethodsUtils.FormatPlateAlter(value); 
        onPlateChange(formattedValue);
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onSearch();  
        }
    };

    return (
        <InputGroup {...props} inside style={styles}>
            <Input
                id={id}
                placeholder={placeholder}
                value={plate}  
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                disabled={readonly}
            />
            <InputGroup.Button onClick={onSearch}>
                <Icon icon="search" />
            </InputGroup.Button>
        </InputGroup>
    );
};


class RegisterChecklistPage extends React.Component<any, RegisterChecklistPageState> {
    constructor(props: any) {
        super(props);
        this.state = this.getInitialState();

        this.handleSearch = this.handleSearch.bind(this);
        this.handlePlateChange = this.handlePlateChange.bind(this);
        this.getInitialState = this.getInitialState.bind(this);
        this.handleGetMarks = this.handleGetMarks.bind(this);
        this.handleFinishRegisterVehicle = this.handleFinishRegisterVehicle.bind(this);
        this.handleGetChecklistTables = this.handleGetChecklistTables.bind(this);
        this.handleGoToQuests = this.handleGoToQuests.bind(this);
    }

    getInitialState = () => ({
        checkListType: 1,
        hiddenVehicleField: false,
        hiddenFoundVehicle: true,
        hiddenNotFoundVehicle: true,
        hiddenTableChecklist: true,
        hiddenFinishToolBar: true,
        checklistTable: null,
        plate: "",
        readonlySelectType: false,
        readonlyInputPlate: false,
        readonlyNewPlate: false,
        readonlyNewMark: false,
        readonlyNewModel: false,
        newPlate: "",
        newMark: "",
        newModel: "",
        marks: [],
        hiddenSelectUser: true,
        driver: "",
        currentKM: 0,
        readonlyTableChecklist: false,
        checklistTypes: [],
        foundVehicle: null,
        checklistTables: [],
        userObj: null,
        requestPlate: false,
        checklistTableName: ''
    });
    
    handleReturnListChecklists(): void {
        window.location.href = '/checklist/list';
    }

    async handleGetMarks(): Promise<void> {
        try {
            const response = await axios.get("/checklist/getMarks/");

            const data = response.data

            this.setState({ marks: data });
        } catch (error) {
            console.error("Error fetching marks:", error);
        }
    }

    async handleGetChecklistTables(): Promise<void> {
        try {
            const response = await axios.get("/checklist/get_checklist_tables/" + this.state.userObj?.id)

            if (response.data) {
                this.setState({
                    checklistTables: response.data
                })
            }
        } catch (error) {
            console.log({ error })
        }
    }

    async componentDidMount(): Promise<void> {
        const cookies = parseCookies();
        const cookieValue = cookies.checklist_driver_name;
        const cookieChecklistInformations = cookies["@checklist-informations"];
        const lastVehicle = cookies["@lastVehicle"];

        if (lastVehicle) {
            this.setState({
                plate: lastVehicle
            })

            setTimeout(() => {
                this.handleSearch()
            }, 250)
        }

        if (cookieChecklistInformations) {
            const parseJSONChecklistInformations = JSON.parse(cookieChecklistInformations)
            const checklistTypeSelected = this.state.checklistTypes.find(item => item?.label === parseJSONChecklistInformations?.type)

            this.setState({
                hiddenFinishToolBar: false
            })

            if (checklistTypeSelected?.value) {
                this.setState({
                    checkListType: Number(checklistTypeSelected.value)
                })
            }

            if (parseJSONChecklistInformations?.checklistTableId) {
                this.setState({
                    checklistTable: parseJSONChecklistInformations.checklistTableId,
                    hiddenTableChecklist: false
                })
            }

            if (parseJSONChecklistInformations?.vehicleAlreadyExists) {
                const formattedPlate = await MethodsUtils.FormatPlateAlter(parseJSONChecklistInformations.vehicle?.plate)

                this.setState({
                    plate: formattedPlate,
                    hiddenFoundVehicle: false,
                    foundVehicle: {
                        id: 0,
                        plate: parseJSONChecklistInformations.vehicle?.plate,
                        model: parseJSONChecklistInformations.vehicle?.model,
                        markName: parseJSONChecklistInformations.vehicle?.mark
                    },
                    readonlyInputPlate: true
                })
            } else {
                await this.handleGetMarks()
                const formattedPlate = await MethodsUtils.FormatPlateAlter(parseJSONChecklistInformations.vehicle?.plate)

                this.setState({
                    plate: formattedPlate,
                    hiddenFoundVehicle: true,
                    newMark: parseJSONChecklistInformations.vehicle?.markId,
                    newModel: parseJSONChecklistInformations.vehicle?.model,
                    readonlyInputPlate: true,
                    hiddenNotFoundVehicle: false
                })
            }
        
        } else {
            this.setState({
            hiddenTableChecklist: true,
            hiddenFinishToolBar: true })
        }

        if (!cookieValue) {
            window.location.href = '/checklist';
            return;
        }

        const userObj = JSON.parse(cookieValue)

        if (cookieValue) {
            this.setState({
                driver: userObj.name,
                userObj: userObj
            })
        }

        const checklistTypes = await axios.get("/checklist/get_types")

        if (checklistTypes.data.length > 0) {
            this.setState({
                checklistTypes: checklistTypes.data
            })
        }

        this.handleGetChecklistTables()
    }

    handleFinishRegisterVehicle(): void {
        const { newPlate, newModel, newMark } = this.state;

        if (!newPlate || !newModel || !newMark) {
            Notification.warning({
                title: "Aviso!",
                description: "Preencha todos os campos para cadastrar um veículo",
                placement: "bottomEnd"
            })

            return
        }

        this.setState({
            readonlyNewPlate: true,
            readonlyNewMark: true,
            readonlyNewModel: true
        })

        Notification.success({
            title: "Sucesso!",
            description: "Sucesso!",
            placement: "bottomEnd"
        })
    }

    handleScrollOpen = () => {
        const selectPicker = document.querySelector('.tableSelect');

        if (selectPicker) {
            const selectBottomPosition = selectPicker.getBoundingClientRect().bottom + window.scrollY;

            const viewportHeight = window.innerHeight;

            if (selectBottomPosition < viewportHeight + window.scrollY) {
                window.scrollTo({
                    top: viewportHeight + window.scrollY,
                    behavior: 'smooth',
                });
            }
        }
    }

    async handleGoToQuests(): Promise<void> {
        const { userObj, checkListType, checklistTypes, foundVehicle, newPlate, newMark, newModel, checklistTable, plate, requestPlate, checklistTableName } = this.state;
        
        const tableField = document.getElementById("selectTable") as HTMLInputElement;
        const plateField = document.getElementById("plateCar") as HTMLInputElement;
        
        
        if (!checklistTable) {
            Notification.warning({
                title: "Aviso!",
                description: "Preencha a tabela de checklist para continuar!",
                placement: "topEnd"
            })
            tableField?.focus();

            return;
        }

        const existVehicle: boolean = newPlate || plate ? true : false
        
        if (requestPlate && !existVehicle){
            Notification.warning({
                title: "Aviso!",
                description: "Informe a Placa!",
                placement: "topEnd"
            })
            plateField?.focus();
            return;
        }

        const typeText = checklistTypes.find((item: any) => item?.value === checkListType)

        let saveCookieObj: any;

        if (foundVehicle) {
            const formattedPlate = foundVehicle?.plate.replace(/[^a-zA-Z0-9]/g, "").toUpperCase()

            saveCookieObj = {
                userId: userObj?.id,
                userName: userObj?.name,
                userCpfCnpj: userObj?.cpfCnpj,
                type: typeText?.label,
                vehicleAlreadyExists: true,
                vehicle: {
                    plate: formattedPlate,
                    mark: foundVehicle?.markName,
                    model: foundVehicle?.model
                },
                checklistTableId: checklistTable,
                requestPlate: requestPlate,
                checklistTableName: checklistTableName
            }
        } else {
            let formattedPlate;
            
            if (requestPlate) {
                formattedPlate = plate.replace(/[^a-zA-Z0-9]/g, "").toUpperCase()
                var mark = this.state.marks.find((item: any) => item?.value === newMark)
            } 

            saveCookieObj = {
                userId: userObj?.id,
                userName: userObj?.name,
                userCpfCnpj: userObj?.cpfCnpj,
                type: typeText?.label,
                vehicleAlreadyExists: false,
                vehicle: {
                    plate: formattedPlate,
                    mark: mark?.label,
                    markId: mark?.value,
                    model: newModel,
                },
                checklistTableId: checklistTable,
                requestPlate: requestPlate,
                checklistTableName: checklistTableName
            }
        }

        const saveCookieJsonStringfy = JSON.stringify(saveCookieObj)

        setCookie(null, "@checklistInformations", saveCookieJsonStringfy, {
            maxAge: 60 * 60,
            path: '/',
        })

        const geolocation: IGeolocation = await UseGeolocation.findGeolocation();
        const geo = JSON.stringify(geolocation);
        
            setCookie(null, "@startChecklist", await MethodsUtils.generateDateHour(), {maxAge: 60 * 60, path: '/'})
            setCookie(null, "@geolocation", geo, {maxAge:60 * 60, path: '/'})

        this.setState(this.getInitialState)
        window.location.href = '/checklist/answer';
    }

    handleCleanVehicle = () => {
        this.setState({
            plate: "",
            newPlate: "",
            newMark: "",
            newModel: "",
            hiddenFoundVehicle: true,
            hiddenNotFoundVehicle: true,
            readonlyInputPlate: false
        })
    }

    handleNewPlate = (value: string) => {
        const formattedValue = MethodsUtils.FormatPlate(value);
        this.setState({
            newPlate: formattedValue
        })
    };

    handleSelectType(): void {
        this.setState({
            hiddenVehicleField: false
        });
    }

    async handleSearch(): Promise<void> {
        if (!this.state.plate || this.state.plate.length !== 8) {
            Notification.warning({
                title: "Aviso!",
                description: "Preencha uma placa válida para continuar.",
                placement: "bottomEnd"
            })

            return;
        }

        const { plate } = this.state
        const formattedPlate = await MethodsUtils.UnformatedPlate(plate);


        let foundPlate: boolean;

        let vehicleInformations: any;
        try {
            vehicleInformations = await axios.get("/checklist/get_vehicle_information", {
                params: {
                    plate: formattedPlate,
                    userIsThird: this.state.userObj?.userThird
                }
            })
            if (vehicleInformations.data.canUseVehicle === false) {
                Notification.error({
                    title: "Erro!",
                    description: "Veículo não está disponível para este usuário.",
                    placement: "bottomEnd"
                })

                return;
            }

            if (vehicleInformations?.data) {
                foundPlate = true
            } else {
                foundPlate = false
            }
        } catch (error) {
            foundPlate = false
            console.log({ error })
        }

        if (foundPlate) {
            this.setState({
                hiddenFoundVehicle: false,
                hiddenTableChecklist: false,
                readonlyInputPlate: true,
                foundVehicle: vehicleInformations.data,
                hiddenSelectUser: false,
                hiddenFinishToolBar: false
            });
        } else {
            this.setState({
                readonlyInputPlate: true,
                hiddenNotFoundVehicle: false,
                hiddenTableChecklist: false,
                hiddenSelectUser: false,
                hiddenFinishToolBar: false,
                foundVehicle: null
            });
        }
    }

    handlePlateChange(value: string): void {
        // Atualiza o valor da placa no estado, mantendo a formatação
        this.setState({
            plate: value
        });
    }

    handleChecklistTable = (selectedTable: ChecklistTable) => {
        this.setState({
            checklistTable: Number(selectedTable.value),
            checklistTableName: selectedTable.label,
            requestPlate: selectedTable.requestPlate,
        }, () => { this.forceUpdate() });

    };

    handleChecklistTableChange = (value: number | string, event: any): void => {
        const selectedTable = this.state.checklistTables.find((item) => item.value === value);
        if (selectedTable) {
            this.handleChecklistTable(selectedTable);
        } else {
            console.warn("Tabela selecionada não encontrada!");
        }
    };

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.headerContainer} onClick={this.handleReturnListChecklists}>
                        <ArrowLeftLine className={styles.arrow} onClick={this.handleReturnListChecklists} />
                        <span className={styles.returnPageText}>Voltar ao Início</span>
                    </div>
                    <h1 className={styles.userName}>Incluir Checklist</h1>
                </div>
                
                <div className={styles.container}>
                    <div className={styles.mainContainer}>
                        <div className={styles.headerButtonsContainer}></div>
                        <div className={styles.formContainer}>
                            <div className={styles.userInformations}>
                                <span style={{ fontFamily: 'Roboto, Helvetica Neue, Arial, sans-serif', fontSize: '20px', lineHeight: '1.5' }}><strong>Informações do Motorista</strong></span>
                                <Input value={this.state.driver} disabled />
                            </div>
                            <div className={styles.containerText}>
                                <span style={{ fontFamily: 'Roboto, Helvetica Neue, Arial, sans-serif', fontSize: '20px', lineHeight: '1.5' }}><strong>Informações do Checklist</strong></span>
                                {/* <Button appearance="primary" color="violet" onClick={this.handleResetChecklist}><Reload /></Button> */}
                            </div>
                           
                            <div className={styles.form}>
                                <SelectPicker
                                    data={this.state.checklistTypes}
                                    placeholder="Selecione o tipo do checklist"
                                    style={{ width: "100%" }}
                                    value={this.state.checkListType}
                                    defaultValue={0}
                                    onChange={(e) => {
                                        this.setState({ checkListType: e, readonlySelectType: true });
                                        // this.handleSelectType();
                                    }}
                                // disabled={this.state.readonlySelectType}
                                /> 
                                    {this.state.requestPlate && (
                                        <div className={styles.searchPlateContainer}>
                                                <CustomInputGroupWidthButton
                                                    id="plateCar"
                                                    placeholder="Pesquise por uma placa..."
                                                    onSearch={this.handleSearch}
                                                    onPlateChange={this.handlePlateChange}
                                                    plate={this.state.plate}
                                                    readonly={this.state.readonlyInputPlate}
                                                />
                                                <Button appearance="primary" color="violet" onClick={this.handleCleanVehicle}><Icon icon="reload" /></Button>
                                            </div>
                                        )}

                                {!this.state.hiddenFoundVehicle && this.state.requestPlate && (
                                    <Panel header={`Placa: ${this.state.plate}`} collapsible bordered>
                                        <div className={styles.cardText}>
                                            <span><strong>Placa:</strong> {this.state.plate}</span>
                                            <span><strong>Marca:</strong> {this.state.foundVehicle?.markName}</span>
                                            <span><strong>Modelo:</strong> {this.state.foundVehicle?.model}</span>
                                        </div>
                                    </Panel>
                                )}

                                {!this.state.hiddenNotFoundVehicle && this.state.requestPlate && (
                                    <Panel header={"Veículo não encontrado!"} collapsible bordered defaultExpanded={true}>
                                        <div className={styles.cardText}>
                                            <span className={styles.registerVehicleText}>Cadastre o seu veículo</span>
                                            <span><strong>Placa:</strong> <Input value={this.state.plate} disabled /></span>
                                            <span><strong>Marca:</strong>
                                                <br />
                                                <SelectPicker
                                                    data={this.state.marks}
                                                    placeholder="Selecione uma marca..."
                                                    onOpen={this.handleGetMarks}
                                                    style={{ width: "100%" }}
                                                    disabled={this.state.readonlyNewMark}
                                                    value={this.state.newMark}
                                                    onChange={(e) => {
                                                        this.setState({ newMark: e })
                                                        console.log({ e })
                                                    }}
                                                />
                                            </span>
                                            <span><strong>Modelo:</strong> 
                                                <Input 
                                                    onChange={(e) => this.setState({ newModel: e })} 
                                                    value={this.state.newModel} 
                                                    disabled={this.state.readonlyNewModel} /></span>
                                        </div>
                                        {/* <Button appearance="primary" color="green" style={{ marginTop: '1rem' }} onClick={this.handleFinishRegisterVehicle}>Finalizar</Button> */}
                                    </Panel>
                                )}
                                
                                        <div className={styles.containerText2}>
                                            <span style={{ fontFamily: 'Roboto, Helvetica Neue, Arial, sans-serif', fontSize: '20px', lineHeight: '1.5' }}><strong>Tabelas de Checklist</strong></span>
                                        </div>

                                        <SelectPicker
                                            id="selectTable"
                                            data={this.state.checklistTables}
                                            placeholder="Selecione uma tabela checklist..."
                                            className="tableSelect"
                                            style={{ width: "100%" }}
                                            value={this.state.checklistTable} 
                                            onOpen={this.handleScrollOpen}
                                            onChange={this.handleChecklistTableChange}
                                        />
                                
                                <div className={styles.containerToolBar}>
                                    <Button 
                                        appearance="primary" 
                                        color="cyan" 
                                        style={{ width: '100%' }} 
                                        onClick={this.handleGoToQuests}>
                                        Ir para questionário
                                    </Button>
                                </div>
                                
                                
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}

export default RegisterChecklistPage;
