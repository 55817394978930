import React, { Component, useEffect, useState } from "react";
import { Button, Notification, Input, InputGroup } from "rsuite";
import classNames from "classnames";
import './styles/styles.css'
import LogoKey from './assets/lock.svg';
import { destroyCookie, parseCookies } from "nookies";
import md5 from "md5";
import axios from "axios";
import { ResponseDefault } from "../../../../core/interfaces/interfaces";
import ModalOverlay from "../../../../components/modalOverlay/ModalOverlay";

type Data = {
    userId: number,
    userPassword: string
}

const NewPassword = () => {
    const [password, setpassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isProccess, setIsProccess] = useState(false);
    const [modalSending, setModalSending] = useState(false);
    const [modalSendingMessage, setSendingMessage] = useState('')


    useEffect(() => {
        const cookies = parseCookies();
        const cookieValue = cookies.checklist_driver_name;
        if (!cookieValue) {
         window.location.href = '/checklist';
        }
    }, []);

    
    const handleInputPasswordChange = (value: string) => {
        setpassword(value)
    }

    const setNewPassword = async () => {
        const cookies = parseCookies();
        const cookieValue = cookies.checklist_driver_name;
        const dataCookie = JSON.parse(cookies.checklist_driver_name)

        if (!password) {
            Notification.error({
                title: "Erro",
                description: "Senha não informada!",
                duration: 6000
            })
            
            const passwordField = document.getElementById("fieldPassword") as HTMLInputElement;
            if (passwordField) passwordField.classList.add("style.invalidField");

            passwordField?.focus();
            return;
        } 

        const passwordField = document.getElementById("fieldPassword") as HTMLInputElement;
        if (passwordField) passwordField.classList.remove("invalid-field");
        
        setIsProccess(true)

        try {
            
            const data: Data = {
                userId: Number(dataCookie?.id),
                userPassword: md5(password) 
            }
            
            const sendReq: ResponseDefault = await axios.post("/checklist/password", {data}).then((res) => {return res.data})
            
            // console.log({sendReq})
            
            if (sendReq.status === 200) {
                setModalSending(true)
                setSendingMessage(sendReq.message)
                setTimeout(() => {
                    setIsProccess(false);
                    window.location.href = '/checklist/list';
                }, 3000);

            } else if (sendReq.status === 202) {
                setIsProccess(false);
                Notification.error({
                    title: "AVISO",
                    description: sendReq.message.toString(),
                    duration: 4000
                });

            } else {
                setIsProccess(false);
                Notification.error({
                    title: "ERRO",
                    description: sendReq.message || "Erro inesperado!",
                    duration: 4000
                });
            }

        } catch (e) {
            console.error(e);
            setIsProccess(false)
        }
    }

    const closeNewPassword = () => {
        const cookies = parseCookies();
        const cookieValue = cookies.checklist_driver_name;
            
        if (cookieValue) {
            destroyCookie(null, 'checklist_driver_name', {path: '/',})
        }

        window.history.back();
    }

    
    return (
        <>
            <div className="containerPassword">
                
                <div className="font-center">
                    TROCA DE SENHA <br /> 
                    OBRIGATÓRIA
                </div>
                    <div>
                        <img 
                            src={LogoKey}
                            alt="Logo Brasmeg"
                            className="logoPassword"/>
                    </div>
                
                    <div className="subcontainer">
                        <div>
                            <strong>Digite a Nova Senha:</strong>
                                <InputGroup inside>
                                    <Input
                                        id="fieldPassword"
                                        autoFocus={true}
                                        placeholder="Digite sua senha"
                                        value={password}
                                        onChange={(value) => handleInputPasswordChange(value)}
                                        type={showPassword ? 'text' : 'password'}
                                        />
                                    <InputGroup.Button 
                                        onClick={() => setShowPassword(!showPassword)}>
                                        <i className={classNames("fas fa-fw", "fa-" + (!showPassword ? "eye" : "low-vision"))}></i>
                                    </InputGroup.Button>
                                </InputGroup>
                                <div>
                                    <span>Campo Obrigatório - Mínimo 6 caracteres</span>    
                                </div>
                            </div>
                              <div className="containner-btn">
                                <div>
                                    <Button
                                        disabled={password.length <= 5 || isProccess}
                                        className="btnSend"
                                        appearance="primary"
                                        size="lg"
                                        onClick={setNewPassword}>
                                        SALVAR SENHA
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        disabled={isProccess}
                                        className="btnSend"
                                        color="red"
                                        size="lg"
                                        appearance="primary"
                                        onClick={closeNewPassword}>
                                        cancelar
                                    </Button>
                                </div>
                            </div>    
                    </div>
                
            </div>

            <ModalOverlay
                    isVisible={modalSending}
                    message={modalSendingMessage}
                    iconClassName="fas fa-spinner fa-spin"
                />
        </>
    )
}

export default NewPassword;