import React from "react";
import styles from "./styles.module.scss";
import { Button, Input, InputGroup, Notification } from "rsuite";
import axios from "axios";
import { setCookie, parseCookies, destroyCookie } from "nookies";
import { MethodsUtils } from "../../core/utils/MethodsUtils";
import classNames from "classnames";
import ModalOverlay from "../../components/modalOverlay/ModalOverlay";
import imgBrasmeg  from "../../../public/BrasmegChecklist.png"
import { UserLogin } from "../../core/interfaces/interfaces";
import md5 from "md5";


interface ChecklistPageState {
    cpf: string;
    loadingButton: boolean;
    password: string;
    showPassword: boolean,
    modalSending: boolean,
    modalSendingMessage: string,
    showLoginFields: boolean,
    isUserValid: boolean,
    btnSearchCPF: boolean
}

class ChecklistPage extends React.Component<any, ChecklistPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            cpf: '',
            loadingButton: false,
            password: '',
            showPassword: false,
            modalSending: false,
            modalSendingMessage: "",
            showLoginFields: false,
            isUserValid: false, 
            btnSearchCPF: true
        };

        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputPasswordChange = this.handleInputPasswordChange.bind(this)
    }

    componentDidMount(): void {
        localStorage.removeItem("token")
    }

    changeLoadingButtonOff() {
        this.setState({
            loadingButton: false
        })
    }

    changeLoadingButtonOn() {
        this.setState({
            loadingButton: true
        })
    }

   handleUserSearch = async () => {
        
        const unformattedCPF = this.state.cpf.replace(/\D/g, '');

        if (!unformattedCPF) {
            Notification.warning({
                title: "Aviso!",
                description: "Preencha o campo de CPF para prosseguir.",
                placement: "bottomEnd",
            });
            return;
        }

        try {
            this.setState({loadingButton: true})

            const response = await axios.get(`/checklist/getUser/${unformattedCPF}`);
            const user = response.data;

            if (user.status !== 200) {
                Notification.error({
                    title: "Erro!",
                    description: "Usuário não encontrado ou inativo.",
                    placement: "bottomEnd",
                });
                this.setState({loadingButton: false})
                return;
            }

            if (!user.user_first_access) {
                this.setState({ isUserValid: true });
                this.setState({ btnSearchCPF: false})
                this.setState({loadingButton: false})
            } else {
                const cookies = parseCookies();
                const cookieValue = cookies.checklist_driver_name;
            
                if (cookieValue) {
                    destroyCookie(null, 'checklist_driver_name', {
                        path: '/',
                    });

                    destroyCookie(null, '@checklistInformations', {
                        path: '/',
                    });
                }
            
                const userObj = {
                    id: user.user_id,
                    name: user.user_name,
                    cpfCpnj: unformattedCPF,
                    userThird: user.user_third
                }
            
                const userObjStringfy = JSON.stringify(userObj)
            
                setCookie(null, "checklist_driver_name", userObjStringfy, {
                    maxAge: 60 * 60 * 1,        // 1 hora
                    path: '/',
                })
                
                
                this.setState({
                    modalSending: true,
                    modalSendingMessage: "Troca de Senha Obrigatória...",
                });

                setTimeout(() => {
                    this.setState({ modalSending: false });
                    window.location.href = '/checklist/newpassword';
                }, 2000);
            }
        } catch (error) {
            this.setState({loadingButton: false})
            Notification.error({
                title: "Erro!",
                description: "Ocorreu um problema ao buscar o usuário.",
                placement: "bottomEnd",
            });
        }
    };

    async handleSubmitForm() {
        if (!this.state.password) {
            Notification.error({
                title: "Erro",
                description: "Senha não informada!",
                duration: 6000
            })
            
            const passwordField = document.getElementById("fieldPassword") as HTMLInputElement;
            if (passwordField) passwordField.classList.add("style.invalidField");

            passwordField?.focus();
            return;
        } 

        const passwordField = document.getElementById("fieldPassword") as HTMLInputElement;
        if (passwordField) passwordField.classList.remove("invalid-field");
      
      
        this.changeLoadingButtonOn()
        
      
        const unformattedCPF = this.state.cpf.replace(/\D/g, '');
      
        if (!unformattedCPF) {
            Notification.warning({
                title: "Aviso!",
                description: "Preencha o campo de CPF para prosseguir.",
                placement: "bottomEnd"
            })
            this.changeLoadingButtonOff()
            return
        }
        
        const userLogin: UserLogin = {
            userCpfCnpj: unformattedCPF,
            userPass: md5(this.state.password)
        }
        
        const user = await axios.post('/checklist/uservalidator', {userLogin}).then((res) => {return res});
      
        if (user.data.status !== 200) {
            Notification.error({
                title: "Erro!",
                description: "Usuário inativo, não habilitado para realizar o checklist ou não encontrado.",
                placement: "bottomEnd"
            })
            this.changeLoadingButtonOff()
            return;
        
        } else if (! user.data.userIsValid) {
                Notification.error({
                    title: "Erro!",
                    description: "Senha Incorreta para este usuário!",
                    placement: "bottomEnd"
                })
                this.changeLoadingButtonOff()
                passwordField?.focus();
                return
            
            } else if (!user.data.user_first_access) {
                const cookies = parseCookies();
                const cookieValue = cookies.checklist_driver_name;
            
                if (cookieValue) {
                    destroyCookie(null, 'checklist_driver_name', {
                        path: '/',
                    });
                }
            
                const userObj = {
                    id: user.data.user_id,
                    name: user.data.user_name,
                    cpfCpnj: unformattedCPF,
                    userThird: user.data.user_third
                }
            
                const userObjStringfy = JSON.stringify(userObj)
            
                setCookie(null, "checklist_driver_name", userObjStringfy, {
                    maxAge: 60 * 60 * 1,        // 1 hora
                    path: '/',
                })
            
                this.changeLoadingButtonOff()
            
                Notification.success({
                    title: "Sucesso!",
                    description: "Login efetuado com sucesso.",
                    placement: "bottomEnd"
                })
            
                setTimeout(() => {
                    window.location.href = '/checklist/list';
                }, 1000)
            } else {
                const userObj = {
                    id: user.data.user_id,
                    name: user.data.user_name,
                    cpfCpnj: unformattedCPF,
                    userThird: user.data.user_third
                }
            
                const userObjStringfy = JSON.stringify(userObj)
            
                setCookie(null, "checklist_driver_name", userObjStringfy, {
                    maxAge: 60 * 60 * 1,        // 1 hora
                    path: '/',
                })
                
                this.changeLoadingButtonOff()
                
                this.setState({
                        modalSending: true,
                        modalSendingMessage: "Troca de Senha Obrigatória...",
                });
                
                setTimeout(() => {
                    
                    this.setState({
                        modalSending: false,
                        modalSendingMessage: "redirecionado",
                    });
                
                    window.location.href = '/checklist/newpassword';
                }, 2000)
            }
  }  
  
    async handleInputChange(value: string) {
         const formattedCPF = await MethodsUtils.formatDocument(value);
            this.setState({
                    cpf: formattedCPF,
                    isUserValid: false, 
        });
    }

    handleInputPasswordChange(value: string) {
        const passwordField = document.getElementById("fieldPassword") as HTMLInputElement;
        if (value.trim() !== "") {
            passwordField?.classList.remove("invalid-field");
        }

        this.setState({password: value})
    }

    render() {
        const { cpf, password, showPassword, loadingButton, modalSending, modalSendingMessage, isUserValid, btnSearchCPF } = this.state;

        return (
            <>
                <div className={styles.container}>
                    <img
                        src="/BrasmegChecklist.png"
                        alt="Logo Brasmeg"
                        className={styles.logo}
                    />
                    <div className={styles.containerForm}>
                        <div className={styles.form}>
                            <div className={styles.checklistTitle}>
                                <span><strong>CPF / CNPJ</strong></span>
                                <InputGroup inside>
                                    <Input
                                        placeholder="Digite seu CPF/CNPJ..."
                                        value={cpf}
                                        onChange={(e) => this.handleInputChange(e)}
                                    />
                                    <InputGroup.Button onClick={this.handleUserSearch}>
                                        <i className="fas fa-search"></i>
                                    </InputGroup.Button>
                                </InputGroup>
                                
                                {btnSearchCPF && (
                                    <Button
                                        className={styles.btnSend}
                                        appearance="primary"
                                        color="cyan"
                                        loading={loadingButton}
                                        onClick={this.handleUserSearch}> BUSCAR CPF
                                    </Button>
                                )}

                                {isUserValid && (
                                    <>
                                        <br />
                                        <span><strong>SENHA</strong></span>
                                        <InputGroup inside>
                                            <Input
                                                id="fieldPassword"
                                                autoFocus={true}
                                                placeholder="Digite sua senha"
                                                value={password}
                                                onChange={(e) => this.handleInputPasswordChange(e)}
                                                type={showPassword ? 'text' : 'password'}
                                            />
                                            <InputGroup.Button 
                                                onClick={() => this.setState({ showPassword: !showPassword })}>
                                                <i className={classNames("fas fa-fw", "fa-" + (!showPassword ? "eye" : "low-vision"))}></i>
                                            </InputGroup.Button>
                                        </InputGroup>

                                        <Button
                                            className={styles.btnSend}
                                            appearance="primary"
                                            color="cyan"
                                            loading={loadingButton}
                                            onClick={() => this.handleSubmitForm()}>
                                            ACESSAR
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>


                <ModalOverlay
                    isVisible={modalSending}
                    message={modalSendingMessage}
                    iconClassName="fas fa-spinner fa-spin"
                />
            </>
        );
    }
}

export default ChecklistPage;