export enum ProfileApplication {
    
    DESENVOLVIMENTO,
    HOMOLOGAÇÃO,
    PRODUÇÃO,
    LOCAL

}

// Fiz esta função para devolver a chave referente ao valor.
const getEnumDescription = (enumValue: ProfileApplication): string => {
    return ProfileApplication[enumValue];
}

export {getEnumDescription}