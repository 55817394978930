import React, { useRef, useEffect } from "react";
import styles from "./styles.module.scss";
import { Button } from "rsuite";
import SignatureCanvas from 'react-signature-canvas';
import ReactModal from 'react-modal';


const lockOrientation = () => {
    if (window.screen.orientation && (window.screen.orientation as any).lock) {
        (window.screen.orientation as any).lock("portrait").catch((err: any) => console.log("Erro ao bloquear a orientação:", err));
    }
};

const unlockOrientation = () => {
    if (window.screen.orientation && window.screen.orientation.unlock) {
        window.screen.orientation.unlock();
    }
};

interface SignaturePadProps {
    handleSetSignature: (img: string) => void;
    handleChangeSignatureModal: () => void;
}

const SignaturePad: React.FC<SignaturePadProps> = ({ handleSetSignature, handleChangeSignatureModal }) => {
    const sigCanvas = useRef<SignatureCanvas | null>(null);

    const clear = () => {
        sigCanvas.current?.clear();
    };

    const save = () => {
        if (sigCanvas.current) {
            const dataUrl = sigCanvas.current.toDataURL("image/png"); // Removed getTrimmedCanvas()
            handleSetSignature(dataUrl); // Atualiza o estado da assinatura
        }
    };

    useEffect(() => {
        lockOrientation();
        return () => {
            unlockOrientation();
        };
    }, []);

    return (
        <ReactModal
            isOpen
            style={{
                content: {
                    width: '550px',
                    height: '95%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    marginBottom: '1rem'
                },
            }}
        >
            <div className={styles.modalContainer} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: "100%" }}>

                <div className={styles.signatureButtons} style={{ position: 'absolute', top: '80px', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                    <Button onClick={clear} appearance="primary" color="orange" style={{ transform: 'rotate(90deg)' }}>Limpar</Button>
                    <Button onClick={save} appearance="primary" color="green" style={{ top: '50px', transform: 'rotate(90deg)' }}>Salvar</Button>
                </div>
                <div>
                    <SignatureCanvas
                        ref={sigCanvas}
                        canvasProps={{
                            style: {
                                border: '.3px solid #000',
                                backgroundColor: '#f5f5f5',
                                marginRight: '80dvw',
                                width: '65dvw',
                                height: '80dvh',
                                // transform: 'translate(-50%, -50%) rotate(90deg)'
                            },
                        }}
                    />
                    <div className={styles.signatureButtons} style={{ position: 'absolute', top: '200px', left: '-40px', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                        <span className={styles.signatureTitle} style={{ top: '300px', transform: 'rotate(90deg)' }}><strong>Assinatura Eletrônica</strong></span>
                    </div>
                </div>


            </div>
        </ReactModal>

    );
};

export default SignaturePad;