import { IGeolocation } from "../interfaces/interfaces";

export class UseGeolocation {
  
    static findGeolocation = async (): Promise<IGeolocation> => {
        return new Promise<IGeolocation>((resolve) => {
          if (!navigator.geolocation) {
            resolve({
              latitude: null,
              longitude: null,
              accuracy: null,
              error: "Geolocalização não é suportada neste navegador.",
            });
            return;
          }

          const handleSuccess = (position: GeolocationPosition) => {
            const { latitude, longitude, accuracy } = position.coords;
            resolve({
              latitude,
              longitude,
              accuracy: Math.round(accuracy),
              error: null,
            });
          };

          const handleError = (error: GeolocationPositionError) => {
            let errorMessage = "";
            switch (error.code) {
              case error.PERMISSION_DENIED:
                errorMessage = "Permissão negada pelo usuário para acessar a geolocalização.";
                break;
              case error.POSITION_UNAVAILABLE:
                errorMessage = "A posição não está disponível no momento.";
                break;
              case error.TIMEOUT:
                errorMessage = "Tempo esgotado ao tentar obter a localização.";
                break;
              default:
                errorMessage = "Ocorreu um erro desconhecido ao tentar obter a geolocalização.";
                break;
            }
            resolve({
              latitude: null,
              longitude: null,
              accuracy: null,
              error: errorMessage,
            });
          };

          navigator.geolocation.getCurrentPosition(handleSuccess, handleError, {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0,
          });
        });
      };
}