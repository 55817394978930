import classNames from "classnames";
import React from "react";
import { Redirect, withRouter } from "react-router";
import { Button, ControlLabel, FormGroup, Input, InputGroup, Panel, Notification } from "rsuite";
import InputGroupAddon from "rsuite/lib/InputGroup/InputGroupAddon";
import InputGroupButton from "rsuite/lib/InputGroup/InputGroupButton";
import Logo from "../assets/mind.png";
import { AuthContext } from "../components/authProvider/main";
import { ConfirmModal } from "../components/modal/main";
import { LoginAnimationSvg } from "../svg/LoginAnimationSvg";
import axios from "../utilities/axios";
import qs from "query-string";
import { Helmet } from "react-helmet";
import md5 from 'md5';
import { duration } from "moment";


class LoginPage extends React.Component<any, {}> {
    static contextType = AuthContext;
    public state = {
        isPasswordVisible: false,
        isLoading: false,
        userCpfCnpj: "",
        userPassword: "",
        errorMessage: null,
    };

    doLogin = () => {
        this.setState({ isLoading: true, errorMessage: null });
        
        axios
            .post("/api/v1/auth", {
                user_cpf_cnpj: this.state.userCpfCnpj,
                user_password: md5(this.state.userPassword),
            })
            .then((res) => {
                if (res && res.data && res.data.token) {
                    this.context.setAuth(res.data.token);
                }
            })
            .catch((err) => {
                if (err && err.response && err.response.status !== 200) {
                    // alert(err.response.data.message)
                    this.setState({ errorMessage: err.response.data.message });
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
        // this.props.history.push('/')
    };

    render() {
        if (this.context.isAuthenticated) {
            return <Redirect to="/dashboard" />;
        }
        var { isAuthDone, failedToConnect } = qs.parse(this.props.location.search);
        if (isAuthDone) {
            localStorage.removeItem("currentCompany")
        }

        return (
            <div className="login-page">
                <Helmet>
                    <title>Brasmeg RH - Login</title>
                </Helmet>
                <LoginAnimationSvg />
                <div>
                    <img alt={"Logo"} src={Logo} style={{ position: 'fixed', left: '30px', top: '32px', height: '60px' }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <ConfirmModal show={isAuthDone || failedToConnect}>
                        {isAuthDone ? 'Sua sessão encerrou!!! Faça o Login Novamente!' : 'Houve uma falha na conexão com o servidor!'}
                    </ConfirmModal>
                    <Panel style={{ width: '300px', padding: '20px', border: '1px solid #ccc', borderRadius: '5px', backgroundColor: '#f9f9f9', boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)', textAlign: 'center' }}>
                        <div style={{ margin: '0 auto 20px' }}>
                            <img alt={"Logo"} src={Logo} style={{ width: '100px' }} />
                        </div>
                        <form onSubmit={() => this.doLogin()}>
                            <FormGroup className="mb-4">
                                <ControlLabel>CPF/CNPJ</ControlLabel>
                                <InputGroup>
                                    <InputGroupAddon>
                                        <i className="fas fa-user"></i>
                                    </InputGroupAddon>
                                    <Input
                                        value={this.state.userCpfCnpj} // Define o valor do input baseado no estado
                                        onPressEnter={this.doLogin}
                                        onChange={(v) => this.setState({ userCpfCnpj: v })}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup className="mb-6">
                                <ControlLabel>Senha</ControlLabel>
                                <InputGroup>
                                    <InputGroupAddon>
                                        <i className="fas fa-lock"></i>
                                    </InputGroupAddon>
                                    <Input
                                        value={this.state.userPassword} // Define o valor do input baseado no estado
                                        onPressEnter={this.doLogin}
                                        onChange={(v) => this.setState({ userPassword: v })}
                                        type={this.state.isPasswordVisible ? "text" : "password"}
                                    />
                                    <InputGroupButton onClick={() => this.setState({ isPasswordVisible: !this.state.isPasswordVisible })}>
                                        <i className={classNames("fas fa-fw", "fa-" + (!this.state.isPasswordVisible ? "eye" : "low-vision"))}></i>
                                    </InputGroupButton>
                                </InputGroup>
                            </FormGroup>
                            {/* Botão e outros componentes */}
                        </form>


                        <div style={{ position: 'relative', width: '100%' }}>
                            <small style={{ position: 'absolute', top: '-10px', color: 'red' }}>{this.state.errorMessage}</small>
                            <Button style={{ background: '#181368', width: '100%', marginTop: '5px', fontSize: '16px', border: 'none', borderRadius: '3px', cursor: 'pointer', padding: '10px', color: 'white' }} 
                                    color="green" onClick={this.doLogin} 
                                    loading={this.state.isLoading}>
                                Login
                            </Button>
                            <a href="/resetpassword">Esqueci Minha Senha</a>
                        </div>
                    </Panel>
                </div>

            </div>
        );
    }
}

export default withRouter(LoginPage);
