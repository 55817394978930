// alert(window.location.hostname)
var arr = window.location.href.split("/");
var url = arr[0] + "//" + arr[2]
const config: any = {
    url: `${url}`,
    projectName: 'Brasmeg',
    module_colors: {
        comercial: "#4caf50",
        laboratorio: "#323233",
        preventiva: "#6a30d1",
        configuracoes: "rgb(75, 57, 105)",
        relatorios: "#60e2be",
    },
    modules: [
        { label: "Dashboard Projeto", url: "project", color: "rgb(48, 41, 114)", icon: "fai icon-cog", fullName: "projetos" },
        { label: "Configurações", url: "configuracoes", color: "rgb(75, 57, 105)", icon: "fai icon-config", fullName: "Configurações" },
        { label: "Solicitação", url: "project/transfer_requests/add", color: "#ff5733", icon: "fas fa-plus", fullName: "Solicitação" },
        { label: "Dashboard", url: "dashboard", color: "rgb(116, 82, 93)", icon: "fas fa-home fa-lg", fullName: "Dashboard", visibleDashboard: false },
    ],
};

export var getModule = function (moduleName: string) {
    for (var i in config.modules) {
        if (config.modules[i].url === moduleName) {
            return config.modules[i];
        }
    }
    for (var i in config.main) {
        if (config.main[i].url === moduleName) {
            return config.main[i];
        }
    }
    for (var x in config.bussiness_unities) {
        if (config.bussiness_unities[x].url === moduleName) {
            return config.bussiness_unities[x];
        }
    }
};
export default config;
