import React from "react";
import { Whisper, Tooltip, Button } from "rsuite";
import { ModalImportAttachmentUser } from "./ModalImportAttachmentUser";
import { ModalImportAttachmentManager } from "./ModalImportAttachmentManager";


type state = {
    titleModal: string;
    showModal: boolean;
    functionName: string;
    showManagerModal: boolean;
}

export class UserBrowserlize extends React.Component<any, state> {
    public state = {
        titleModal: '',
        showModal: false,
        functionName: '',
        showManagerModal: false
    }

    componentDidMount(): void {
        // console.log('props ::> ', this.props)
    }

    onOpenModal = ({ title, functionName }: { title: string, functionName: string }) => {
        this.setState({
            showModal: true,
            titleModal: title,
            functionName: functionName
        })
    }

    onOpenManagerModal = ({ title, functionName }: { title: string, functionName: string }) => {
        this.setState({
            showManagerModal: true,
            titleModal: title,
            functionName: functionName
        })
    }

    onCloseModal = () => {
        this.setState({
            showModal: false,
            showManagerModal: false
        })
    }

    render(): React.ReactNode {
        return (
            <>
                <ModalImportAttachmentUser
                    title={this.state.titleModal}
                    crud={this.props.name}
                    show={this.state.showModal}
                    functionNameImport={this.state.functionName}
                    onCLoseModal={this.onCloseModal}
                />

                <ModalImportAttachmentManager
                    title=" de Gestor"
                    crud={this.props.name}
                    show={this.state.showManagerModal}
                    functionNameImport="importManager"
                    onCLoseModal={this.onCloseModal}

                />
                {!this.props.match.url.includes("/checkin/") && (
                    <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Importação Usuários</Tooltip>}>
                        <Button style={
                            {
                                backgroundColor: '#dcdcdc'
                            }
                        }
                            className="mr-2"
                            onClick={() => this.onOpenModal(
                                {
                                    title: 'Usuários',
                                    functionName: 'importUsers'
                                }
                            )}
                        >
                            <i className="fas fa-user-plus"></i>
                        </Button>
                    </Whisper>
                )}

                {!this.props.match.url.includes("/checkin/") && (
                    <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Importação Gestor</Tooltip>}>
                        <Button style={
                            {
                                backgroundColor: '#dcdcdc'
                            }
                        }
                            className="mr-2"
                            onClick={() => this.onOpenManagerModal(
                                {
                                    title: 'Gestor',
                                    functionName: 'importManager'
                                }
                            )}
                        >
                            <i className="fas fa-user-tie"></i>
                        </Button>
                    </Whisper>
                )}

            </>
        )
    }
}