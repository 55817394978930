import { Event } from "../Event";
import axios from "../../utilities/axios";
import { Notification } from "rsuite";
import { ResponseDefault } from "../../core/interfaces/interfaces";

export class UserCpfCnpjEvents extends Event {

    public onBlur = async (value: any, setField: any, setState: any, formValues: any) => {
        if (value){
            this.setState("loadings", ["user_cpf_cnpj"]);
            const response: ResponseDefault = await axios.get(`/api/v1/user/existuser/${value}`)
            
            if (response.status === 200){
                        this.setFieldValue("user_cpf_cnpj", "");
                this.setState("loadings", []);
                Notification.error({
                    title: "ATENÇÃO",
                    description: "Este CPF já está cadastrado!"
                })
            }

             this.setState("loadings", []);
        }

        return
    }
    
}
