import React, { useEffect, useRef } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import iconMark from "../assets/mark.png"


interface MapProps {
  latitude: number;
  longitude: number;
  zoom: number;
  zoomMax: number;
  zoomMin: number;
  scrollWheelZoom: boolean;
  accuracy: number;
}

export const MapComponent: React.FC<MapProps> = ({
  latitude,
  longitude,
  zoom,
  zoomMax,
  zoomMin,
  scrollWheelZoom,
  accuracy,
}) => {
  const mapRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (mapRef.current) {
      const map = L.map(mapRef.current, {
        center: [latitude, longitude],
        zoom,
        zoomControl: true,
        maxZoom: zoomMax,
        minZoom: zoomMin,
        scrollWheelZoom: scrollWheelZoom, 
      });

      
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(map);

      // Criando o ícone personalizado para o marcador
      const customIcon = L.icon({
        iconUrl: iconMark, 
        iconSize: [40, 60],
        iconAnchor: [20, 40], 
        popupAnchor: [0, -40], 
      });

      // Adicionando o marcador no mapa
      L.marker([latitude, longitude], { icon: customIcon })
        .addTo(map)
        .on("click", () =>
          map.setView([latitude, longitude], zoomMax, {
            animate: true,
            duration: 1,
          })
        )
        .bindPopup("Você está aqui!")
        .openPopup();


      L.circle([latitude,longitude], {
        radius: (accuracy), 
        color: "#1E90FF",
        fillColor: "#1E90FF",
        fillOpacity: 0.1,
      }).addTo(map);
      
      return () => {
        map.remove();
      };
    }
  }, [latitude, longitude, zoom, zoomMax, zoomMin, scrollWheelZoom, accuracy]);

  return <div ref={mapRef} style={{ width: "100%", height: "350px" }} />;
};